import React from 'react'
import "../../App.css";
import { PuffLoader } from 'react-spinners';


const Loader = () => {
return (
    <div className="loading-screen">
        <PuffLoader color="#123abc" height={15} width={5}/>
    </div>
    );
}

export default Loader