import "../../App.css";
import Comment from "react-spinners/PulseLoader";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { GetDecryptAccessTokenformat, GetDecryptEmailformat, GetDecryptReferenceNumberformat } from "../../Components/Common/Common";
import { useDispatch } from "react-redux";
import { ReGenerateToken } from "../../redux/auth/authSlice";

const Chatbot = () => {
  const [handleinput, setHandleinput] = useState([]);
  const [convertation, setConvertation] = useState([]);
  // const [status, setStatus] = useState("idle");

  const email = GetDecryptEmailformat();

  const referenceNumber = GetDecryptReferenceNumberformat();

  const answerScroll = useRef(null);

  const dispatch = useDispatch();

  const HandleChange = (e) => {
    setHandleinput(e.target.value);
  };

  const HandleSubmit = async (e) => {
    e?.preventDefault();
    // setStatus("loading");
    setHandleinput("");

    let previousResponse = [...convertation];

    previousResponse[previousResponse?.length - 1] = {
      ...previousResponse[previousResponse?.length - 1],
      animate: false,
    };

    previousResponse.push({ question: handleinput, answer: "", animate: true });
    setConvertation(previousResponse);

    async function query() {
      try {
        // let token = sessionStorage?.getItem("access_token");
        const token = GetDecryptAccessTokenformat();
        const response = await fetch("https://api.weaiu.com/api/chat/v1/huggingFaceChat", {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            user_message: handleinput,
            email: email,
            reference_number: referenceNumber,
          }),
        });
        if (response?.status === 401) {
          dispatch(ReGenerateToken(query, {}, dispatch));
          return;
        }

        const result = await response.json();

        if (result?.error) {
          toast.error(result?.message);
          previousResponse[previousResponse?.length - 1] = {
            ...previousResponse[previousResponse?.length - 1],
            answer: result?.message,
          };
          if (answerScroll.current) {
            answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
          }

          setConvertation(previousResponse);
          return;
        }

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          answer: result?.message?.data[0],
        };

        setConvertation(previousResponse);
        // setStatus("success");
        if (answerScroll.current) {
          answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
        }
      } catch (error) {
        toast.error(error);
        // setStatus("error");
      }
    }

    query().then((response) => {
      // console.log(JSON.stringify(response));
      return response;
    });
  };

  //   const HandleSubmit = async (e) => {
  //     e?.preventDefault();
  //     console.log(e, "eeeee");

  //     // dispatch(SetLoading(true));
  //     setHandleinput("");
  //     setStatus("progress");

  //     // setQuestion(handleinput);
  //     setQuestion((prev) => [...prev, handleinput]);

  //     async function query(data) {
  //       const response = await fetch(
  //         "https://api-inference.huggingface.co/models/meta-llama/Meta-Llama-3-8B-Instruct",
  //         {
  //           headers: {
  //             Authorization: "Bearer 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",
  //             "Content-Type": "application/json",
  //           },
  //           method: "POST",
  //           body: JSON.stringify(data),
  //         }
  //       );
  //       //   dispatch(SetLoading(false));
  //       const result = await response.json();
  //       //   setResult(  result[0]?.generated_text);

  //       setResult((prev) => [...prev, result[0]?.generated_text]);
  //       setStatus("completed");

  //       return result;
  //     }

  //     // query({"inputs": "Can you please let us know more details about your "}).then((response) => {
  //     //     console.log(JSON.stringify(response));
  //     // });

  //     query({ inputs: handleinput }).then((response) => {
  //       console.log(JSON.stringify(response));
  //     });
  //   };

  const AnimatedText = ({ text }) => {
    const [displayText, setDisplayText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        if (currentIndex < text?.length) {
          setDisplayText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          setConvertation((prevState) => {
            let newState = [...prevState];
            newState[newState?.length - 1] = {
              ...newState[newState?.length - 1],
              animate: false,
            };
            return newState;
          });
          clearInterval(interval);
        }
      }, 10); // Adjust the speed of typing animation by changing the interval
      return () => clearInterval(interval);
    }, [currentIndex, text]);

    return <div className="animated-text">{displayText}</div>;
  };

  const HandleClearChat = () => {
    toast.success("Chat Cleared");
    setConvertation(
      convertation.filter((ele) => {
        return;
      })
    );
  };

  const Answer = ({ answerText, animateText }) => {
    if (animateText) {
      return (
        <div style={{ color: "black" }}>
          <p>
            <AnimatedText text={answerText} />
          </p>
        </div>
      );
    }
    if (answerText) {
      return (
        <div style={{ color: "black" }}>
          <p>{answerText}</p>
        </div>
      );
    }
    return <Comment height="50" width="50" color="#4b49ac" visible={true} wrapperStyle={{}} backgroundColor="#F4442E" ariaLabel="comment-loading" wrapperClass="comment-wrapper" />;
  };

  // const HandleClearChat=()=>{
  //   setConvertation((prev)=>[...prev,{question:"",answer:""}])
  // }

  return (
    <>
      <div className="Chat_bot_css ">
        <div className="d-flex justify-content-center">
        <h1 style={{fontSize:"16px"}}>ChatBot</h1>
        </div>
        <div class="main-div-msgchatbot">
          <div class="input-box">
            <div class="input-group">
              <span class="input-group-text">
                <i class="fa-regular fa-message"></i>
              </span>
              <input
                type="text"
                class="form-control input_css_for_all_placeholder"
                name="text"
                onKeyDown={(e) => {
                  if (e.key === "Enter" && handleinput?.length > 0) {
                    HandleSubmit();
                  }
                }}
                onChange={(e) => HandleChange(e)}
                value={handleinput}
                placeholder="Ask a question..."
              />
              <button class="btn btn-link" onClick={(e) => HandleSubmit(e)} disabled={handleinput?.length == 0}>
                <i class={`fa-regular fa-paper-plane   ${handleinput?.length>0?"chatai_icon_css":""}`}></i>
              </button>
              <button class="btn btn-link" onClick={(e) => HandleClearChat(e)} disabled={convertation == ""}>
                <i class={`fa-solid fa-trash-can  ${convertation?.length>0?"chatai_icon_css":""}`}></i>
              </button>
            </div>
          </div>
        </div>

        {/* ************Message Section********************** */}

        <div className="message_css" ref={answerScroll}>
          {convertation.map((item, index) => {
            return (
              <div key={index} style={{ width: "100%", maxWidth: "98%" }}>
                <div className="right-ms">
                  <div className="Question_css">
                    <p className="mx-3 my-2" style={{ color: "black" }}>
                      {item?.question}
                    </p>
                  </div>
                </div>

                <div className="left-ms">
                  <div className="Answer_css my-4">
                    <Answer answerText={item?.answer} animateText={item?.animate} />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Chatbot;
