import React, { useEffect, useState } from "react";
import DOMPurify from "dompurify";

const Format = ({ answer, isShare }) => {
  const [visibleText, setVisibleText] = useState("");

  useEffect(() => {
    setVisibleText(answer);
  }, [answer]);

  const transformText = (text) => {
    if (!text) return "";

    let transformedText = text
      .replace(/\n\n-/g, "<br/><br/> ● ")
      .replace(/\n-/g, "<br/> ● ")
      .replace(/\n\n/g, "<br/><br/>")
      .replace(/\n/g, "<br/>")
      .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
    transformedText = transformedText
      .replace(/([*\\^$|#\s:%])/g, "<b>$1</b>")
      .replace(
        /\b(million|billion|hundred|thousand|trillion|crore)\b/gi,
        "<b>$1</b>"
      )
      .replace(/(\d[\d,]*)/g, "<b>$1</b>");

    return transformedText;
  };

  const sanitizedText = DOMPurify.sanitize(transformText(visibleText));

  return (
    <span
      className={isShare ? "" : "text-line"}
      dangerouslySetInnerHTML={{ __html: `<p>${sanitizedText}</p>` }}
    />
  );
};

export default Format;
