import { Routes, Route } from "react-router-dom";
import Dashboard from "./Dashboard";
import OTP from "../../Components/Authentication/OTP";
import Profile from "../User-panel/Profile";

import SocialPolicies from "./SocialPolicies";
import OTPVerify from "../../Components/Authentication/VerifyOTP";
import Layout from "../../Components/Layout";
import Login from "../../Components/Authentication/Login";
import Home from "./Home";
import Loginwithfb from "../../Components/Loginwithfb";
import Chatbot from "./Chatbot";
import Gmail from "./Gmail";
import Instagram from "./Instagram";
import Mail from "./Mail";

import InstagramPosts from "./InstagramPosts";
import ChatAI from "./ChatAI";
import AllowInstaAccess from "../../Components/UserPanel/Instagram/AllowInstaAccess";
import TransactionHistory from "../../Components/UserPanel/Instagram/TransactionHistory";
import BillingStatus from "./BillingStatus";


function App() {
  return (
    <>
      <Layout>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="/dashboard" element={<Dashboard />}></Route>
          <Route path="/otp" element={<OTP />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/home" element={<Home />}></Route>
          <Route path="/verifyotp" element={<OTPVerify />}></Route>
          <Route path="/instagramaccess" element={<AllowInstaAccess />}></Route>
          <Route path="/socialPolicies" element={<SocialPolicies />}></Route>
          <Route path="/loginfb" element={<Loginwithfb />}></Route>
          <Route path="/Chatbot" element={<Chatbot />}></Route>
          <Route path="/gmail" element={<Gmail />}></Route>
          <Route path="/Instagram" element={<Instagram />}></Route>
          <Route path="/mail" element={<Mail />}></Route>
          <Route path="/transactionhistory" element={<TransactionHistory />}></Route>
          <Route path="/igposts" element={<InstagramPosts />}></Route>
          <Route path="/billing" element={<BillingStatus />}></Route>
          <Route path="/Chatai" element={<ChatAI />}></Route>
         
        </Routes>
      </Layout>
    </>
  );
}

export default App;
