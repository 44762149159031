import React from "react";
import "../../App.css";
import { Link, useLocation } from "react-router-dom";

import { FaInstagram, FaFileAlt, FaRocketchat, FaEnvelope, FaHouseUser, FaCaretLeft } from "react-icons/fa";

const Sidebar = ({ HandleToggle }) => {
  const pathName = useLocation();

  return (
    <>
      <div className="side-bar">
        <a className="abs-bk" onClick={HandleToggle}>
          <FaCaretLeft className="icon" />
        </a>
        <div className="logo-sec">
          <img className="w-100 logo-top" src="images/logo.png" alt="" />
          <h1
            style={{
              color: "white",
              textAlign: "center",
              fontFamily: "serif",
              fontWeight: "600",
            }}
          >
            WEAIU
          </h1>
        </div>
        <div className="link-div">
          <ul className="link-ul">
            <li>
              <Link to="/home" className={pathName.pathname === "/home" ? "active" : ""}>
                <FaHouseUser className="icons-svg" />
                Home
              </Link>
            </li>

            <li>
              <Link to="/gmail" className={pathName.pathname === "/gmail" ? "active" : ""}>
                <FaEnvelope className="icons-svg" />
                Gmail
              </Link>
            </li>

            <li>
              <Link to="/Chatai" className={pathName.pathname === "/Chatai" ? "active" : ""}>
                <FaRocketchat className="icons-svg" />
                ProjectW(AI)
              </Link>
            </li>

            <li>
              <Link to="/instagram" className={pathName.pathname === "/instagram" ? "active" : ""}>
                <FaInstagram className="icons-svg" />
                Instagram
              </Link>
            </li>

            <li>
              <Link to="/Chatbot" className={pathName.pathname === "/Chatbot" ? "active" : ""}>
                <FaRocketchat className="icons-svg" />
                ChatBot
              </Link>
            </li>

            <li>
              <Link to="/billing" className={pathName.pathname === "/billing" ? "active" : ""}>
              <FaFileAlt className="icons-svg" />
               Billing Status
              </Link>
            </li>

            <li>
              <Link to="/socialPolicies" className={pathName.pathname === "/socialPolicies" ? "active" : ""}>
                <FaFileAlt className="icons-svg" />
                Policies
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
