// import axios from "axios";
import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
// import CryptoJS from 'crypto-js';

const Home = () => {


  // const [googleauth, setGoogleauth] = useState([]);

  // const [text, setText] = useState('');
  // const [encryptedText, setEncryptedText] = useState('');
  // const [decryptedText, setDecryptedText] = useState('');

  // const secretKey = 'my-secret-key';
  // Replace with your actual secret key

  // const handleEncrypt = () => {
  //   const ciphertext = CryptoJS.AES.encrypt(text, secretKey)?.toString();
  //   setEncryptedText(ciphertext);
  // };

  // const handleDecrypt = () => {
  //   const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
  //   console.log(bytes,"byteee");

  //   const originalText = bytes.toString(CryptoJS.enc.Utf8);
  //   console.log(originalText,"orginalll");

  //   setDecryptedText(originalText);
  // };

  // useEffect(() => {
  //   async function query() {
  //     try {
  //       const response = await axios.post(
  //         "https://api.weaiu.com/google-api/authorize",
  //         {
  //           email: sessionStorage?.getItem("email"),
  //           reference_number: sessionStorage?.getItem("reference_number"),
  //         },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",

  //           }
  //         }
  //       );
  //       const result = response.data;
  //       console.log(result, "resss");
  //     } catch (error) {
  //       console.log(error, "errrrr");
  //       // setStatus("error");
  //     }
  //   }

  //   query();
  // }, []);

  // useEffect(() => {
  //   async function query() {
  //     try {
  //       const response = await fetch(
  //         "https://api.weaiu.com/google-api/authorize",

  //         {
  //           headers: {
  //                         "Content-Type": "application/json",

  //                       },
  //           method: "POST",
  //           body: JSON.stringify({
  //             email: sessionStorage?.getItem("email"),
  //             reference_number: sessionStorage?.getItem("reference_number"),
  //           }),
  //         }
  //       );
  //       const result = await response.json();
  //       console.log(result, "resss");
  //     } catch (error) {
  //       console.log(error, "errrrr");
  //       // setStatus("error");
  //     }
  //   }
  //   query();
  // }, []);

  // console.log(googleauth, "googleauth");

  const GetDecryptEmailformat = () => {
    // const getEmail = "U2FsdGVkX18SMa0/bXD25jXW540tL/6OIj4b2M59qTUNav1q5E6+Tdxwh+e19qN0C1Uz4QkVtBHNzFxSk9wlf7IISlPgY4LvtXwj7nJ6ExjLsGb7ucQOiBpk3jqVwbxk"
    const getEmail = "U2FsdGVkX194FgoVWn0UXfLlkQwf9fzHWYGAkMYZnw9xR3eLp2wfgvNGkL2brx/J/9au5/9sT0Q/yXvvmHhheeOPO6pGOa++pAK2h/87AFPIJcASAuOJavtTwi4QtCVY"
  
    if (!getEmail) {
      console.error("No email found in localStorage");
      return null;
    }
  
    try {
      const bytes = CryptoJS.AES.decrypt(getEmail, "ASDFGHJK45678@#MJH5432LOHATYX&");
  
      if (!bytes || bytes.sigBytes <= 0) {
        console.error("Decryption failed or produced empty result");
        return null;
      }
  
      const originalEmail = bytes.toString(CryptoJS.enc.Utf8);
  
      if (!originalEmail) {
        console.error("Failed to convert bytes to string or produced empty string");
        return null;
      }

      console.log(originalEmail,"email")
  
      return originalEmail;
    } catch (error) {
      console.error("An error occurred during decryption:", error);
      return null;
    }
  };


  
  
  
  console.log(GetDecryptEmailformat(),"GetDecryptEmailformat")

  return (
    <>
      <h1 className="text-center">Home Page</h1>

      {/* <div style={{ padding: '20px', maxWidth: '600px', margin: 'auto' }}>
      <h1>CryptoJS Example</h1>
      <div style={{ marginBottom: '20px' }}>
        <textarea
          label="Text to Encrypt"
          variant="outlined"
          fullWidth
          value={text}
          onChange={(e) => setText(e.target.value)}
        />
      </div>
      <button variant="contained" color="primary" onClick={handleEncrypt}>
        Encrypt
      </button>
      <div style={{ marginTop: '20px', marginBottom: '20px' }}>
        <textarea
          label="Encrypted Text"
          variant="outlined"
          fullWidth
          value={encryptedText}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
      <button variant="contained" color="secondary" onClick={handleDecrypt}>
        Decrypt
      </button>
      <div style={{ marginTop: '20px' }}>
        <textarea
          label="Decrypted Text"
          variant="outlined"
          fullWidth
          value={decryptedText}
          InputProps={{
            readOnly: true,
          }}
        />
      </div>
    </div> */}
    </>
  );
};

export default Home;
