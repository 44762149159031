import React, { useEffect } from "react";

const SocialPolicies = () => {
  return (
    <>
      <div className="privacyPolicy_css">
        <h1>Privacy & Policy</h1>
        <p>
          When integrating features that involve user data, especially on
          platforms like Instagram, it's essential to adhere to certain policies
          and guidelines to ensure user privacy, security, and compliance with
          platform rules. Here are some general policies you should consider
          when adding and deleting data related to Instagram usernames in your
          app.
          </p>
          <div className="my-3">
            <h3 style={{ fontWeight: "600", fontSize: "20px" }}>
              {" "}
              User Consent:
            </h3>
          </div>
         <p> Always obtain explicit consent from users before accessing or using
          their Instagram usernames or any other personal information. Clearly
          explain why you need this data and how it will be used.</p>
          <div className="my-3">
            <h3 style={{ fontWeight: "600", fontSize: "20px" }}>
              {" "}
              Data Security:
            </h3>
          </div>
          <p>Implement robust security measures to protect user data, including
          Instagram usernames, from unauthorized access, misuse, or data
          breaches. Use encryption, secure storage practices, and regular
          security audits.</p>
          <div className="my-3">
            <h3 style={{ fontWeight: "600", fontSize: "20px" }}>
              {" "}
              Privacy Policy:
            </h3>
          </div>
          <p>Maintain a clear and comprehensive privacy policy that discloses how
          you collect, use, store, and share user data, including Instagram
          usernames. Make sure your privacy policy is easily accessible to
          users.</p>
          <div className="my-3">
            <h3 style={{ fontWeight: "600", fontSize: "20px" }}>
              {" "}
              Compliance with Instagram API Terms:
            </h3>
          </div>
         <p> If you are using Instagram APIs to access user data, ensure compliance
          with Instagram's API terms and developer policies.</p>
          <div className="my-3">
            <h1 style={{ fontSize: "25px" }}>
              {" "}
             Follow their guidelines for data usage, rate limits, and data
              retention:
            </h1>
          </div>
          <h5 style={{ fontWeight: "600", fontSize: "20px" }}>
           Data Retention and Deletion:
          </h5>
          <p className="my-3">
            Define clear policies and procedures for data retention and
            deletion. If a user requests deletion of their Instagram username or
            other data, promptly remove it from your system and any associated
            backups.
          </p>
          <h5 style={{ fontWeight: "600", fontSize: "20px" }}> User Rights:</h5>
          <p className="my-3">
            Respect user rights regarding their data, including the right to
            access, rectify, and delete their information. Provide mechanisms
            for users to manage their data preferences within your app.
          </p>
          <h5 style={{ fontWeight: "600", fontSize: "20px" }}>
            Transparency and Accountability:
          </h5>
          <p className="my-3">
            Be transparent about your data practices and accountable for how you
            handle user data. Respond promptly to user inquiries, complaints,
            and data access requests.
          </p>
          <h5 style={{ fontWeight: "600", fontSize: "20px" }}>
            Compliance with Laws and Regulations:{" "}
          </h5>{" "}
          <p className="my-3">
            Ensure compliance with relevant data protection laws and
            regulations, such as the General Data Protection Regulation (GDPR)
            in Europe or the California Consumer Privacy Act (CCPA) in the
            United States.
          </p>
          <h5 style={{ fontWeight: "600", fontSize: "20px" }}>
            Regular Audits and Reviews:
          </h5>
          <p className="my-3">
            Conduct regular audits and reviews of your data handling practices
            to identify and address any compliance issues, security
            vulnerabilities, or privacy risks.
          </p>
          <h5 style={{ fontWeight: "600", fontSize: "20px" }}>
            Educate Users:{" "}
          </h5>{" "}
          <p className="my-3">
            Educate users about their privacy rights, data security best
            practices, and how to protect their information while using your
            app, including their Instagram usernames.
          </p>
      </div>
    </>
  );
};

export default SocialPolicies;
