import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  GetProfile,
  GetUserWalletBalance,
  GetUserWalletDetails,
  LogoutHandler,
  UserWalletDetails,
} from "../../redux/auth/authSlice";
import Loader from "../Loader/Loader";
import {
  GetDecryptAccessTokenformat,
  GetDecryptEmailformat,
  GetDecryptReferenceNumberformat,
} from "../Common/Common";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { FaBars, FaCaretLeft } from "react-icons/fa";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import avatar from "../../Utils/Images/userpic.png";
import { Link } from "react-router-dom";
import { UserOutlined, LogoutOutlined } from "@ant-design/icons";

import { Button, ConfigProvider, Flex, Tooltip } from "antd";
import "./navbar.css";
const Navbar = ({ HandleToggle }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [copied, setCopied]= useState(false);
  const { isLoading, getUserWalletDetails, walletBalanceUser, googleSigninData, profileDetails } = useSelector((state) => state?.counter);
  console.log(googleSigninData, "googleSigninDatagoogleSigninData");
  console.log(profileDetails, "profileDetails111");

  const HandleLogin = (item) => {
    navigate(item);
  };

  const walletAddress = getUserWalletDetails || "";
  const displayAddress =
    walletAddress.length > 10
      ? `${walletAddress.substring(0, 6)}...${walletAddress.substring(
          walletAddress.length - 4
        )}`
      : walletAddress;


  const handleCopyClick = () => {
    navigator.clipboard
      .writeText(walletAddress)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  const text = (
    <span>{walletBalanceUser == "You have not enough balance 0.,please add more balance." ? "You do not have enough balance in your wallet. Please add some balance" : walletBalanceUser} </span>
  );

  // const textWallet = <span> Please Import PrivateKey from UserProfile</span>;

  const emailData = GetDecryptEmailformat();

  const access_token = GetDecryptAccessTokenformat();

  useEffect(() => {
    if (getUserWalletDetails) {
      dispatch(GetUserWalletBalance({ getUserWalletDetails }));
    }
  }, [getUserWalletDetails]);

  useEffect(() => {
    let obj = {
      user_id: GetDecryptEmailformat(),
      email: GetDecryptEmailformat(),
      reference_number: GetDecryptReferenceNumberformat(),
    };

    if (obj?.user_id) {
      dispatch(GetUserWalletDetails({ obj, dispatch }));
      dispatch(GetProfile({ obj, dispatch }));
    }
  }, [dispatch]);

  // const HandleLogout = () => {
  //   let obj = {
  //     email: emailData,
  //   };
  //   dispatch(LogoutHandler({ obj, navigate, dispatch }));
  // };


  const items = [
    {
      label: (
        <div className="Navbar_css">
          <UserOutlined style={{ marginRight: 8 }} />
          <Link to="/profile">Profile</Link>
        </div>
      ),
      key: "0",
      onClick: () => {
        // Your onClick function for Profile
        console.log("Profile clicked");
      },
    },
    {
      label: (
        <div className="Navbar_css">
          <LogoutOutlined style={{ marginRight: 8 }} />
          Logout
        </div>
      ),
      key: "1",
      onClick: () => {
        // Your onClick function for Logout
        let obj = {
          email: emailData,
        };
        dispatch(LogoutHandler({ obj, navigate, dispatch }));
        console.log("Logout clicked");
      },
    },
  ];


  const profilePictureUrl = profileDetails[0]?.profile_picture_url
    ? `${profileDetails[0]?.profile_picture_url}?t=${new Date().getTime()}`
    : "";

  const username = localStorage.getItem("UserName");

  return (
    <>
      {isLoading && <Loader />}
      <section className="dasboard-all top-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="header-flex">
                <ul>
                  <li>
                    <button onClick={HandleToggle} className="tog-bt">
                      {/* {/ <i className="fa-solid fa-bars"></i> /} */}
                      <FaBars className="fa-solid fa-bars" />
                      <FaCaretLeft className="fa-solid fa-caret-left" />
                      {/* {/ <i className="fa-solid fa-caret-left"></i> /} */}
                    </button>
                  </li>

                  <li
                    className="d-flex align-items-center flex-wrap "
                    style={{ gap: "13px" }}
                  >
                    <div>
                      {access_token ? (
                        <div
                          style={{
                            alignItems: "center",
                            display: "flex",
                            gap: "5px",
                          }}
                        >

<div className="wallet-container">
                            <div
                              className="wallet-address"
                              title={walletAddress}
                              onClick={handleCopyClick}
                            >
                              {displayAddress}
                            </div>
                            {copied && (
                              <div className="copy-message">Copied!</div>
                            )}
                          </div>
                          {/* <div className="abs-mobile" style={{ fontWeight: "600" }}>
                            {getUserWalletDetails ? getUserWalletDetails : ""}
                          </div> */}

                          {/* <div className="navbar_connect_button_css">
                          <ConnectButton showBalance={{ largeScreen: false }} />

                          </div> */}

                          {/* <Flex vertical justify="center" align="center" className="demo">
                            <Flex
                              justify="center"
                              align="center"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Tooltip placement="bottom" title={textWallet}>
                              <div className="navbar_connect_button_css">
                            <ConnectButton />
                          </div>
                              </Tooltip>
                            </Flex>
                          </Flex> */}

                          <Flex
                            vertical
                            justify="center"
                            align="center"
                            className="demo"
                          >
                            <Flex
                              justify="center"
                              align="center"
                              style={{
                                whiteSpace: "nowrap",
                              }}
                            >
                              <Tooltip placement="bottom" title={text}>
                                <div>
                                  <button
                                    className="log-out-bt"
                                    style={{ cursor: "not-allowed" }}
                                  >
                                    ${" "}
                                    {walletBalanceUser &&
                                    walletBalanceUser !==
                                      "You have not enough balance 0.,please add more balance."
                                      ? walletBalanceUser
                                      : "0"}
                                  </button>
                                </div>
                              </Tooltip>
                            </Flex>
                          </Flex>

                          {/* <button className="log-out-bt " onClick={HandleLogout}>
                          {" "}
                          Logout
                        </button> */}
                        </div>
                      ) : (
                        <button
                          className="log-out-bt"
                          onClick={() => HandleLogin("/")}
                        >
                          {" "}
                          Login
                        </button>
                      )}
                    </div>

                    <div
                      className="profile-right"
                      style={{ cursor: "pointer" }}
                    >
                      <Dropdown
                        menu={{
                          items,
                        }}
                        trigger={["click"]}
                      >
                        <Space>
                          <div className="profile-card">
                            <div className="profile-card-img">
                              <img
                                className="rounded-circle"
                                src={
                                  profilePictureUrl ? profilePictureUrl : avatar
                                }
                                height={50}
                                width={50}
                                alt="Profile"
                              />
                            </div>
                            <p style={{ fontWeight: "600" }}>
                              {username ? username : ""}
                            </p>
                          </div>
                        </Space>
                      </Dropdown>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Navbar;
