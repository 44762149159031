import CryptoJS from "crypto-js";

const secretKey = "k9ZUY`W+oFV6!T4D";

// ***********************************SET*****************************************************

export const SetEncryptEmailformat = (val) => {
  const email = CryptoJS.AES.encrypt(val, secretKey)?.toString();
  const returnData = localStorage?.setItem("email", email);
  return returnData;
};


export const SetEncrypytUID = (val) => {
  return CryptoJS.AES.encrypt(val, secretKey)?.toString();

};

export const SetEncrypytPID = (val) => {
  return CryptoJS.AES.encrypt(val, secretKey)?.toString();

};

export const SetEncrypytInstagramUID = (val) => {
  return CryptoJS.AES.encrypt(val, secretKey)?.toString();

};

export const SetEncrypytInstagramPID = (val) => {
  return CryptoJS.AES.encrypt(val, secretKey)?.toString();

};

export const SetEncryptReferenceNumberformat = (val) => {
  const reference_number = CryptoJS.AES.encrypt(val, secretKey)?.toString();
  const returnData = localStorage?.setItem("reference_number", reference_number);
  return returnData;
};

export const SetEncryptAccessTokenformat = (val) => {
  const access_token = CryptoJS.AES.encrypt(val, secretKey)?.toString();
  const returnData = localStorage?.setItem("access_token", access_token);
  return returnData;
};

export const SetEncryptUserNameformat = (val) => {
  const instagramUserName = CryptoJS.AES.encrypt(val, secretKey)?.toString();
  const returnData = sessionStorage?.setItem("username", instagramUserName);
  return returnData;
};

export const SetUserData = (val) => {
  return localStorage?.setItem("UserName", val);

};


export const SetEncryptPasswordformat = (val) => {
  const instagramPassword = CryptoJS.AES.encrypt(val, secretKey)?.toString();
  const returnData = sessionStorage?.setItem("password", instagramPassword);
  return returnData;
};

export const SetEncryptTokenID = (val) => {
  const TokenID = CryptoJS.AES.encrypt(val, secretKey)?.toString();
  return TokenID
  // const returnData = sessionStorage?.setItem("tokenId", TokenID);
  // return returnData;
};

// ****************************************** GET*************************************************

export const GetDecryptEID = (val) => {


  if (!val) {
    console.error("No email found in cookies");
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(val, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      console.error("Decryption failed or produced empty result");
      return null;
    }

    const originalEmail = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalEmail) {
      console.error("Failed to convert bytes to string or produced empty string");
      return null;
    }

    return originalEmail;
  } catch (error) {
    console.error("An error occurred during decryption:", error);
    return null;
  }
};

export const GetDecryptUID = (val) => {

  if (!val) {
    console.error("No email found in localStorage");
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(val, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      console.error("Decryption failed or produced empty result");
      return null;
    }

    const originalEmail = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalEmail) {
      console.error("Failed to convert bytes to string or produced empty string");
      return null;
    }

    return originalEmail;
  } catch (error) {
    console.error("An error occurred during decryption:", error);
    return null;
  }
};

export const GetDecryptInstagramEID = (val) => {


  if (!val) {
    console.error("No email found in cookies");
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(val, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      console.error("Decryption failed or produced empty result");
      return null;
    }

    const originalEmail = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalEmail) {
      console.error("Failed to convert bytes to string or produced empty string");
      return null;
    }

    return originalEmail;
  } catch (error) {
    console.error("An error occurred during decryption:", error);
    return null;
  }
};

export const GetDecryptInstagramUID = (val) => {

  if (!val) {
    console.error("No email found in localStorage");
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(val, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      console.error("Decryption failed or produced empty result");
      return null;
    }

    const originalEmail = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalEmail) {
      console.error("Failed to convert bytes to string or produced empty string");
      return null;
    }

    return originalEmail;
  } catch (error) {
    console.error("An error occurred during decryption:", error);
    return null;
  }
};


export const GetDecryptEmailformat = () => {
  const getEmail = localStorage?.getItem("email");

  if (!getEmail) {
    console.error("No email found in localStorage");
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(getEmail, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      console.error("Decryption failed or produced empty result");
      return null;
    }

    const originalEmail = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalEmail) {
      console.error("Failed to convert bytes to string or produced empty string");
      return null;
    }

    return originalEmail;
  } catch (error) {
    console.error("An error occurred during decryption:", error);
    return null;
  }
};

export const GetDecryptReferenceNumberformat = () => {
  const getReferenceNumber = localStorage?.getItem("reference_number");

  if (!getReferenceNumber) {
    console.error("No email found in localStorage");
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(getReferenceNumber, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      console.error("Decryption failed or produced empty result");
      return null;
    }

    const originalReferenceNumber = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalReferenceNumber) {
      console.error("Failed to convert bytes to string or produced empty string");
      return null;
    }

    return originalReferenceNumber;
  } catch (error) {
    console.error("An error occurred during decryption:", error);
    return null;
  }
};

export const GetDecryptAccessTokenformat = () => {
  const getAccessToken = localStorage?.getItem("access_token");

  if (!getAccessToken) {
    console.error("No email found in localStorage");
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(getAccessToken, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      console.error("Decryption failed or produced empty result");
      return null;
    }

    const originalAccessToken = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalAccessToken) {
      console.error("Failed to convert bytes to string or produced empty string");
      return null;
    }

    return originalAccessToken;
  } catch (error) {
    console.error("An error occurred during decryption:", error);
    return null;
  }
};


export const GetDecryptUserNameformat = () => {
  const getInstagramUserName = sessionStorage?.getItem("username");

  if (!getInstagramUserName) {
    console.error("No username found in session Storage");
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(getInstagramUserName, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      console.error("Decryption failed or produced empty result");
      return null;
    }

    const originalInstagramUserName = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalInstagramUserName) {
      console.error("Failed to convert bytes to string or produced empty string");
      return null;
    }

    return originalInstagramUserName;
  } catch (error) {
    console.error("An error occurred during decryption:", error);
    return null;
  }
};


export const GetDecryptPasswordformat = () => {
  const getInstagramPassword = sessionStorage?.getItem("password");

  if (!getInstagramPassword) {
    console.error("No email found in session storage");
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(getInstagramPassword, secretKey);

    if (!bytes || bytes.sigBytes <= 0) {
      console.error("Decryption failed or produced empty result");
      return null;
    }

    const originalInstagramPassword = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalInstagramPassword) {
      console.error("Failed to convert bytes to string or produced empty string");
      return null;
    }

    return originalInstagramPassword;
  } catch (error) {
    console.error("An error occurred during decryption:", error);
    return null;
  }
};

export const GetDecryptEmailformatccssscscs = (val) => {

  // const UserPrivateKey = "U2FsdGVkX18SMa0/bXD25jXW540tL/6OIj4b2M59qTUNav1q5E6+Tdxwh+e19qN0C1Uz4QkVtBHNzFxSk9wlf7IISlPgY4LvtXwj7nJ6ExjLsGb7ucQOiBpk3jqVwbxk"


  const UserPrivateKey = val;

  if (!UserPrivateKey) {
    console.error("No email found in localStorage");
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(UserPrivateKey, "ASDFGHJK45678@#MJH5432LOHATYX&");

    if (!bytes || bytes.sigBytes <= 0) {
      console.error("Decryption failed or produced empty result");
      return null;
    }

    const originalPrivateKey = bytes.toString(CryptoJS.enc.Utf8);

    if (!originalPrivateKey) {
      console.error("Failed to convert bytes to string or produced empty string");
      return null;
    }



    return originalPrivateKey;
  } catch (error) {
    console.error("An error occurred during decryption:", error);
    return null;
  }
};




