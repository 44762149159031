import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Input, InputNumber, Select, Tabs } from "antd";
import {
  getServerCostGraph,
  GetServerDailyCost,
  GetUserLimit,
  OpenAPIBillingCost,
  SetUserLimit,
  StabilityDiffusionCost,
} from "../../redux/auth/authSlice";
import Loader from "../../Components/Loader/Loader";
import "../../style/billing.css";
import { Line } from "react-chartjs-2";
import { Tooltip as Tip } from "antd";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { GetDecryptEmailformat } from "../../Components/Common/Common";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const { TabPane } = Tabs;

const BillingStatus = () => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const [years, setYears] = useState([]);
  console.log("years", years);

  const [serverCostData, setServerCostData] = useState(null);
  const {
    isLoading,
    ServerDailyCost,
    BillingCostOpenAI,
    userlimitdata,
    StabilityDiffusion,
    CostGraph,
  } = useSelector((state) => state.counter);
  console.log("CostGraph", CostGraph);
  const [BillingData, setBillingData] = useState({
    aws: "",
    gemini: "",
    huggingface: "",
    openai: "",
    stability: "",
  });

  const [error, setError] = useState({});

  useEffect(() => {
    const body = {
      user_id: GetDecryptEmailformat(),
    };
    dispatch(OpenAPIBillingCost({ dispatch }));
    dispatch(GetServerDailyCost({ dispatch }));
    dispatch(StabilityDiffusionCost({ dispatch }));
    dispatch(GetUserLimit({ dispatch, body }));
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await dispatch(getServerCostGraph(currentYear));
      if (data) {
        setServerCostData(data.data);
      }
    };

    fetchData();
  }, [dispatch, currentYear]);

  const generateChartData = (serverCostData, unit) => {
    const labels = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const data = labels.map((month) => parseFloat(serverCostData[month]) || 0);
    return {
      labels,
      datasets: [
        {
          label: `Costs (${unit})`,
          data,
          borderColor: "rgba(75, 192, 192, 1)",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderWidth: 2,
          fill: true,
        },
      ],
    };
  };

  const UserLimitReached =
    "When your limit is reached, you will receive a notification in your email.";

  const awsCost = ServerDailyCost?.Amount || 0;
  const awsUnit = ServerDailyCost?.Unit || "USD";

  const openAIAmount = BillingCostOpenAI?.total_cost || 0;
  const openAIUnit = BillingCostOpenAI?.Unit || "USD";

  const stabilityDiffusionCost = StabilityDiffusion?.total_cost_in_dollar || 0;
  const stabilityDiffusionUnit = StabilityDiffusion?.Unit || "USD";

  const awsData = generateChartData(serverCostData || {}, awsUnit);
  const openAIData = generateChartData(openAIAmount, openAIUnit);
  const stabilityDiffusionData = generateChartData(
    stabilityDiffusionCost,
    stabilityDiffusionUnit
  );

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: $${context.raw}`,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Month",
        },
      },
      y: {
        title: {
          display: true,
          text: `Cost (${awsUnit})`,
        },
        ticks: {
          callback: (value) => `$${value}`,
        },
      },
    },
  };

  const HandleChange = (name, value) => {
    setBillingData({
      ...BillingData,
      [name]: value,
    });

    if (value !== "") {
      setError((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    }
  };

  const Validation = () => {
    const newErrors = {};

    if (!BillingData.aws) {
      newErrors.aws = "This field is required";
    }

    setError(newErrors);
    const hasErrors = Object.keys(newErrors).length > 0;
    return hasErrors;
  };

  const HandleSubmit = (e) => {
    e?.preventDefault();
    const val = Validation();

    if (val) {
      return;
    }
    const obj = {
      user_id: GetDecryptEmailformat(),
      limit: BillingData.aws,
    };
    setBillingData((prev) => ({
      aws: "",
    }));
    dispatch(SetUserLimit({ obj, dispatch }));
  };

  useEffect(() => {
    const initialYears = Array.from({ length: 5 }, (_, i) => currentYear + i);
    setYears(initialYears);
  }, []);

  useEffect(() => {
    setYears((prevYears) => {
      const newYears = Array.from({ length: 5 }, (_, i) => currentYear + i);
      return [...new Set([...prevYears, ...newYears])];
    });
  }, [currentYear]);

  const handleYearChange = (value) => {
    setCurrentYear(value);
    dispatch(getServerCostGraph(value));
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className="billing-container">
        <h2 className="main-txt">Billing Statistics</h2>
        <Tabs defaultActiveKey="1" className="billing-tabs">
          <TabPane tab="AWS" key="1">
            <div className="aws-tab-content">
              <div className="d-flex justify-content-end align-items-center">
                <Tip placement="top" title={UserLimitReached}>
                  <i className="fa-solid fa-circle-info mx-2"></i>
                  Your threshold Amount is:{" "}
                  <span style={{ fontWeight: 600 }}>{userlimitdata}$ USD</span>
                </Tip>
              </div>

              <h3>AWS Billing Details</h3>

              <p>
                <strong>Blended Cost:</strong> {awsCost} {awsUnit}
              </p>
              <div className="d-flex justify-content-center align-items-center">
                <InputNumber
                  min={0}
                  value={BillingData?.aws}
                  placeholder="Please enter amount"
                  name="aws"
                  addonAfter="$"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      HandleSubmit(e);
                    }
                  }}
                  onChange={(value) => HandleChange("aws", value)}
                />
                <Button
                  className="mx-3"
                  type="primary"
                  onClick={(e) => HandleSubmit(e)}
                >
                  Submit
                </Button>
                <div className="dp-sel mx-3">
                  <Select
                    defaultValue={currentYear}
                    onChange={handleYearChange}
                    style={{ width: 120, marginLeft: "16px" }}
                  >
                    {years.map((year) => (
                      <Option key={year} value={year}>
                        {year}
                      </Option>
                    ))}
                  </Select>
                </div>
              </div>

              <div>
                <p style={{ color: "red", marginLeft: "-113px" }}>
                  {error?.aws}
                </p>
              </div>

              <div className="chart">
                <Line data={awsData} options={options} />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Open AI" key="2">
            <div className="open-tab-content">
              <div className="chart-container">
                <h3>Open AI Billing Details</h3>
                <p>
                  <strong>Open AI Cost:</strong> {openAIAmount} {openAIUnit}
                </p>
                <InputNumber
                  min={0}
                  value={BillingData?.openai}
                  placeholder="Please enter amount"
                  name="openai"
                  addonAfter="$"
                  onChange={(value) => HandleChange("openai", value)}
                />
                <Button type="primary" className="mx-3">
                  Submit
                </Button>
                <div className="chart">
                  <Line data={openAIData} options={options} />
                </div>
              </div>
            </div>
          </TabPane>

          <TabPane tab="Stability Diffusion" key="3">
            <div className="stability-diffusion-tab-content">
              <h3>Stability Diffusion Billing Details</h3>
              <p>
                <strong>Stability Diffusion Cost:</strong>{" "}
                {stabilityDiffusionCost} {stabilityDiffusionUnit}
              </p>
              <InputNumber
                min={0}
                value={BillingData?.stability}
                placeholder="Please enter amount"
                name="stability"
                addonAfter="$"
                onChange={(value) => HandleChange("stability", value)}
              />
              <Button type="primary" className="mx-3">
                Submit
              </Button>
              <div className="chart">
                <Line data={stabilityDiffusionData} options={options} />
              </div>
            </div>
          </TabPane>
          <TabPane tab="Gemini AI" key="4">
            <div className="gemini-ai-tab-content">
              <h3>Gemini AI Billing Details</h3>
              <InputNumber
                min={0}
                value={BillingData?.gemini}
                placeholder="Please enter amount"
                name="gemini"
                addonAfter="$"
                onChange={(value) => HandleChange("gemini", value)}
              />
              <Button type="primary" className="mx-3">
                Submit
              </Button>
              <div className="chart">
                <Line data={generateChartData(0, "USD")} options={options} />{" "}
              </div>
            </div>
          </TabPane>
          <TabPane tab="HuggingFace" key="5">
            <div className="huggingface-tab-content">
              <h3>HuggingFace Billing Details</h3>
              <InputNumber
                min={0}
                value={BillingData?.huggingface}
                placeholder="Please enter amount"
                name="huggingface"
                addonAfter="$"
                onChange={(value) => HandleChange("huggingface", value)}
              />
              <Button type="primary" className="mx-3">
                Submit
              </Button>
              <div className="chart">
                <Line data={generateChartData(0, "USD")} options={options} />{" "}
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
};

export default BillingStatus;
