import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ReGenerateToken } from "../../redux/auth/authSlice";
import Comment from "react-spinners/PulseLoader";
import { toast } from "react-toastify";
import { Flex, Tooltip } from "antd";
import Format from "../../Utils/helpers/Format";
import { CopyOutlined, CheckOutlined } from '@ant-design/icons';

const ChatAI = () => {
  const [transcript, setTranscript] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [audioBase64, setAudioBase64] = useState("");
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const [buttonText, setButtonText] = useState("Copy");
  const [isCopied, setIsCopied] = useState(false);
  const [aiagentinput, setAiagentinput] = useState("");
  const [convertation, setConvertation] = useState([]);
  const defaultState = {
    text: false,
    image: false,
    audio: false,
    video: false,
  };

  const text = "Chat";
  const imagetext = "Generate images";
  const audiotext = "Voice Chat";
  const videotext = "Coming Soon";
  const deleteAllchat = "Clear all Chat";

  const [selecticon, setSelectIcon] = useState({ ...defaultState, text: true });
  const dispatch = useDispatch();

  const answerScroll = useRef(null);

  const HandleChange = (e) => {
    setAiagentinput(e?.target?.value);
  };

  const startRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Media Devices not supported");
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    const mediaRecorder = new MediaRecorder(stream);

    mediaRecorder.ondataavailable = (event) => {
      audioChunksRef.current.push(event.data);
    };

    mediaRecorder.onstop = async () => {
      const audioBlob = new Blob(audioChunksRef.current, { type: "audio/wav" });
      audioChunksRef.current = [];

      // Convert Blob to base64
      const base64String = await convertBlobToBase64(audioBlob);
      setAudioBase64(base64String);
    };

    mediaRecorder.start();
    mediaRecorderRef.current = mediaRecorder;
    setIsRecording(true);
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current = null;
      setIsRecording(false);
    }
  };

  useEffect(() => {
    if (audioBase64 && audioBase64?.length > 0) {
      HandleAudioGeneration(audioBase64);
    }
  }, [audioBase64]);

  const convertBlobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  useEffect(() => {
    if (transcript) {
      setAiagentinput(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    if (answerScroll.current) {
      answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
    }
  }, [convertation]);

  const HandleAiagent = (e) => {
    e?.preventDefault();
    setAiagentinput("");
    let previousResponse = [...convertation];

    previousResponse.push({
      question: aiagentinput,
      answer: "",
      animate: true,
    });
    setConvertation(previousResponse);

    async function query() {
      try {
        // Create a FormData object
        let form_data = new FormData();
        form_data.append("text", aiagentinput); // Append your form data

        const response = await fetch(
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/ai_agent_input`,
          {
            method: "POST",
            body: form_data, // Use the FormData object as the body
          }
        );

        if (response?.status === 401) {
          dispatch(ReGenerateToken(query, {}, dispatch));
          return;
        }

        const result = await response.json();

        if (result?.error) {
          toast.error(result?.message);
          previousResponse[previousResponse?.length - 1] = {
            ...previousResponse[previousResponse?.length - 1],
            answer: result?.ai_response?.response_text,
          };

          setConvertation(previousResponse);
          return;
        }

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          answer: result?.ai_response?.response_text,
        };

        setConvertation(previousResponse);
        if (answerScroll.current) {
          answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
        }
      } catch (error) {
        toast.error(error);
      }
    }

    query().then((response) => {
      return response;
    });
  };

  const playAudio = (generatedAudio) => {
    const audio = new Audio(`data:audio/mp3;base64,${generatedAudio}`);
    audio.play();
  };

  const [audioAnswer, setAudioAnswer] = useState(null);

  useEffect(() => {
    if (audioAnswer) {
      setConvertation((prevState) => {
        let previousResponse = [...prevState];

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          ...audioAnswer,
        };

        return previousResponse;
      });
    }
  }, [audioAnswer]);

  const HandleAudioGeneration = async (audiobase64) => {
    let previousResponse = [...convertation];

    let audioobj = {
      audio: audiobase64,
    };

    previousResponse.push({
      answer: "",
      hasAudio: true,
      userAudio: audiobase64,
    });

    setConvertation(previousResponse);

    try {
      const responses = await fetch(
        `${process.env.REACT_APP_apiBaseUrl}/ai/api/voice_chat`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json", // Correct Content-Type header
          },
          body: JSON.stringify(audioobj), // Send JSON data
        }
      );

      const result = await responses.json();

      if (result?.error) {
        const answerResp = {
          answer: result?.error,
          audio: null,
        };

        setAudioAnswer(answerResp);
        return;
      }

      const answerResp = {
        answer: result?.ai_response,
        audio: result.audio,
      };

      setAudioAnswer(answerResp);

      if (result?.status === 200) {
        playAudio(result.audio);
      }

      if (answerScroll.current) {
        answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
      }
    } catch (error) {
      const answerResp = {
        answer: "Something went wrong please try again!",
        audio: null,
      };

      setAudioAnswer(answerResp);
      toast.error(error);
    }
  };

  const HandleAiImageagent = (e) => {
    e?.preventDefault();
    setAiagentinput("");
    let previousResponse = [...convertation];

    previousResponse[previousResponse?.length - 1] = {
      ...previousResponse[previousResponse?.length - 1],
      animate: false,
    };

    previousResponse.push({
      question: aiagentinput,
      answer: "",
      animate: true,
    });
    setConvertation(previousResponse);
    if (answerScroll.current) {
      answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
    }

    async function query() {
      try {
        // Create a FormData object
        let form_data = new FormData();
        form_data.append("prompt", aiagentinput); // Append your form data

        const response = await fetch(
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/image_generation`,
          {
            method: "POST",
            body: form_data, // Use the FormData object as the body
          }
        );

        // if (response?.status === 401) {
        //   dispatch(ReGenerateToken(query, {}, dispatch));
        //   return;
        // }

        const result = await response.json();

        if (result?.error) {
          toast.error(result?.message);
          previousResponse[previousResponse?.length - 1] = {
            ...previousResponse[previousResponse?.length - 1],
            answer: result?.error,
          };

          setConvertation(previousResponse);
          return;
        }

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          answer: result?.image_base64,
          hasImage: true,
        };

        setConvertation(previousResponse);
        // setStatus("success");
        if (answerScroll.current) {
          answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
        }
      } catch (error) {
        toast.error(error);
        // setStatus("error");
      }
    }

    query().then((response) => {
      // console.log(JSON.stringify(response));
      return response;
    });

    // dispatch(AIAGentInput({ aiagentinput }));
  };

  const HandleVideoAiagent = (e) => {
    e?.preventDefault();
    setAiagentinput("");
    let previousResponse = [...convertation];

    previousResponse[previousResponse?.length - 1] = {
      ...previousResponse[previousResponse?.length - 1],
      animate: false,
    };

    previousResponse.push({
      question: aiagentinput,
      answer: "",
      animate: true,
    });
    setConvertation(previousResponse);
    if (answerScroll.current) {
      answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
    }

    async function query() {
      try {
        // Create a FormData object
        let form_data = new FormData();
        form_data.append("prompt", aiagentinput); // Append your form data

        const response = await fetch(
          `${process.env.REACT_APP_apiBaseUrl}/ai/api/generate_video`,
          {
            method: "POST",
            body: form_data, // Use the FormData object as the body
          }
        );

        // if (response?.status === 401) {
        //   dispatch(ReGenerateToken(query, {}, dispatch));
        //   return;
        // }

        const result = await response.json();

        if (result?.error) {
          toast.error(result?.message);
          previousResponse[previousResponse?.length - 1] = {
            ...previousResponse[previousResponse?.length - 1],
            answer: result?.ai_response?.response_text,
          };

          setConvertation(previousResponse);
          return;
        }

        previousResponse[previousResponse?.length - 1] = {
          ...previousResponse[previousResponse?.length - 1],
          answer: result?.image_base64,
          hasImage: true,
        };

        setConvertation(previousResponse);
        // setStatus("success");
        if (answerScroll.current) {
          answerScroll.current.scrollTop = answerScroll.current.scrollHeight;
        }
      } catch (error) {
        toast.error(error);
        // setStatus("error");
      }
    }

    query().then((response) => {
      // console.log(JSON.stringify(response));
      return response;
    });

    // dispatch(AIAGentInput({ aiagentinput }));
  };

  const AnimatedText = ({ text, hasAudio, audio }) => {
    const [displayText, setDisplayText] = useState("");
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const interval = setInterval(() => {
        if (currentIndex < text?.length) {
          setDisplayText((prevText) => prevText + text[currentIndex]);
          setCurrentIndex(function (prevIndex) {
            return prevIndex + 1;
          });
        } else {
          setConvertation((prevState) => {
            let newState = [...prevState];
            newState[newState?.length - 1] = {
              ...newState[newState?.length - 1],
              animate: false,
            };
            return newState;
          });
          clearInterval(interval);
        }
      }, 10);
      return () => clearInterval(interval);
    }, [currentIndex, text]);

    if (hasAudio) {
      return (
        <div className="animated-text">
          {displayText}
          {currentIndex >= text?.length && (
            <span style={{ color: "red" }} onClick={() => playAudio(audio)}>
              Play Again
            </span>
          )}
        </div>
      );
    }

    return <div className="animated-text">{displayText}</div>;
  };

  const WaveLoader = () => {
    return (
      <div class="loading">
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
        <div class="obj"></div>
      </div>
    );
  };

  const Question = ({ hasAudio, userAudio, question }) => {
    if (hasAudio) {
      return (
        <audio src={`data:audio/wav;base64,${userAudio}`} controls>
          Your browser does not support the <code>audio</code> element.
        </audio>
      );
    }
    return question;
  };

  const Answer = ({
    answer,
    animateText,
    hasImage,
    hasAudio,
    hasVideo,
    audio,
  }) => {
    if (hasAudio && !answer) {
      return <WaveLoader />;
    }
    if (hasAudio) {
      return (
        <div style={{ color: "black" }}>
          {answer}
          {audio && (
            <div>
              <i
                onClick={() => playAudio(audio)}
                style={{ cursor: "pointer" }}
                className="fa-regular fa-circle-play"
              ></i>
            </div>
          )}
        </div>
      );
    }

    if (hasVideo) {
      return (
        <video width="400" controls>
          <source src="mov_bbb.mp4" type="video/mp4" />
          <source src="mov_bbb.ogg" type="video/ogg" />
          Your browser does not support HTML video.
        </video>
      );
    }

    if (hasImage) {
      return (
        <img height={700} src={`data:image/png;base64,${answer}`} alt="" />
      );
    }

    if (animateText) {
      return (
        <div className="chat-answer">
          <AnimatedText text={answer} />
        </div>
      );
    }


if (answer) {
  const copyToClipboard = () => {
    navigator.clipboard.writeText(answer)
      .then(() => {
        setButtonText('Copied');
        setIsCopied(true);
        setTimeout(() => {
          setButtonText('Copy');
          setIsCopied(false);
        }, 2000);
      })
      .catch(err => {
        console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className="chat-wrapper">
      <button onClick={copyToClipboard} className="copy-button">
        {isCopied ? <CheckOutlined /> : <CopyOutlined />}
        {buttonText}
      </button>
      <div className="chat-container">
        <div className="chat-answer">
          <Format answer={answer} isShare={false} />
        </div>
      </div>
    </div>
  );
}

    return (
      <div className="chat-answer">
        <Comment
          height="50"
          width="50"
          color="#4b49ac"
          visible={true}
          wrapperStyle={{}}
          backgroundColor="#F4442E"
          ariaLabel="comment-loading"
          wrapperClass="comment-wrapper"
        />
      </div>
    );
  };

  const HandleClearChat = (e) => {
    e?.preventDefault();
    toast.success("Chat Cleared");
    setConvertation(
      convertation.filter((_) => {
        return;
      })
    );
  };

  return (
    <>
      <div className="Chat_bot_cssAiagent ">
        <div className="d-flex justify-content-center">
          <h1 style={{ fontSize: "16px" }}>ProjectW(AI)</h1>
        </div>
        <div class="main-div-msg">
          <div class="input-box">
            {selecticon?.text ? (
              <div class="input-group">
                <span class="input-group-text">
                  <i class="fa-regular fa-message"></i>
                </span>
                <input
                  type="text"
                  class="form-control input_css_for_all_placeholder"
                  name="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && aiagentinput?.length > 0) {
                      HandleAiagent();
                    }
                  }}
                  onChange={(e) => HandleChange(e)}
                  value={aiagentinput}
                  placeholder="Ask a question..."
                />
                <button
                  class="btn btn-link"
                  onClick={(e) => HandleAiagent(e)}
                  disabled={aiagentinput?.length == 0}
                >
                  <i
                    class={`fa-regular fa-paper-plane ${
                      aiagentinput?.length > 0 ? "chatai_icon_css" : ""
                    }`}
                  ></i>
                </button>
                {/* <button class="btn btn-link" onClick={(e) => HandleClearChat(e)} disabled={convertation == ""}>
                  <i class={`fa-solid fa-trash-can   ${convertation?.length > 0 ? "chatai_icon_css" : ""}`}></i>
                </button> */}
              </div>
            ) : selecticon?.audio ? (
              <div className="d-flex justify-content-center recording_css">
                <button onClick={isRecording ? stopRecording : startRecording}>
                  <i
                    style={{
                      color: isRecording ? "red" : "gray",
                      cursor: "pointer",
                    }}
                    class="fa-solid fa-microphone "
                  >
                    <span className="mx-2">
                      {" "}
                      {isRecording ? "Stop Voice Chat" : "Start Voice Chat"}
                    </span>
                  </i>
                </button>
                {/* <button class="btn btn-link mx-2" onClick={(e) => HandleClearChat(e)} disabled={convertation == ""}>
                  <i class={`fa-solid fa-trash-can  ${convertation?.length > 0 ? "chatai_icon_css" : ""}`}></i>
                </button> */}
              </div>
            ) : selecticon?.image ? (
              <div class="input-group">
                <span class="input-group-text">
                  <i class="fa-solid fa-image"></i>
                </span>
                <input
                  type="text"
                  class="form-control input_css_for_all_placeholder"
                  name="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && aiagentinput?.length > 0) {
                      HandleAiImageagent();
                    }
                  }}
                  onChange={(e) => HandleChange(e)}
                  value={aiagentinput}
                  placeholder="Generate an image..."
                />
                <button
                  class="btn btn-link"
                  onClick={(e) => HandleAiImageagent(e)}
                  disabled={aiagentinput?.length == 0}
                >
                  <i
                    class={`fa-regular fa-paper-plane  ${
                      aiagentinput?.length > 0 ? "chatai_icon_css" : ""
                    } `}
                  ></i>
                </button>
                {/* <button class="btn btn-link" onClick={(e) => HandleClearChat(e)} disabled={convertation == ""}>
                    <i class={`fa-solid fa-trash-can   ${convertation?.length > 0 ? "chatai_icon_css" : ""}`}></i>
                  </button> */}
              </div>
            ) : (
              <div class="input-group">
                <span class="input-group-text">
                  <i class="fa-solid fa-video"></i>
                </span>
                <input
                  type="text"
                  class="form-control input_css_for_all_placeholder"
                  name="text"
                  disabled={true}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && aiagentinput?.length > 0) {
                      HandleVideoAiagent(e);
                    }
                  }}
                  onChange={(e) => HandleChange(e)}
                  value={aiagentinput}
                  placeholder="Generate an video..."
                />
                <button
                  class="btn btn-link"
                  onClick={(e) => HandleVideoAiagent(e)}
                  disabled={true}
                >
                  <i
                    class={`fa-regular fa-paper-plane   ${
                      aiagentinput?.length > 0 ? "chatai_icon_css" : ""
                    }`}
                  ></i>
                </button>
                {/* <button class="btn btn-link" onClick={(e) => HandleClearChat(e)} disabled={convertation == ""}>
                    <i class={`fa-solid fa-trash-can   ${convertation?.length > 0 ? "chatai_icon_css" : ""}`}></i>
                  </button> */}
              </div>
            )}
            <div class="row">
              <div class="col-lg-6">
                <div class="button-group">
                  <div class="first-btn">
                    <ul className="first-button">
                      <Flex
                        vertical
                        justify="center"
                        align="center"
                        className="demo"
                      >
                        <Flex
                          justify="center"
                          align="center"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Tooltip placement="bottom" title={text}>
                            <div>
                              <li
                                className={
                                  selecticon?.text ? "active_css_aichatbot" : ""
                                }
                                onClick={() =>
                                  setSelectIcon(() => ({
                                    ...defaultState,
                                    text: true,
                                  }))
                                }
                              >
                                <i class="fa-solid fa-message"></i>
                              </li>
                            </div>
                          </Tooltip>
                        </Flex>
                      </Flex>

                      <Flex
                        vertical
                        justify="center"
                        align="center"
                        className="demo"
                      >
                        <Flex
                          justify="center"
                          align="center"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Tooltip placement="bottom" title={audiotext}>
                            <div>
                              <li
                                className={
                                  selecticon?.audio
                                    ? "active_css_aichatbot"
                                    : ""
                                }
                                onClick={() =>
                                  setSelectIcon(() => ({
                                    ...defaultState,
                                    audio: true,
                                  }))
                                }
                              >
                                <i class="fa-solid fa-microphone"></i>
                              </li>
                            </div>
                          </Tooltip>
                        </Flex>
                      </Flex>

                      <Flex
                        vertical
                        justify="center"
                        align="center"
                        className="demo"
                      >
                        <Flex
                          justify="center"
                          align="center"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Tooltip placement="bottom" title={imagetext}>
                            <div>
                              <li
                                className={
                                  selecticon?.image
                                    ? "active_css_aichatbot"
                                    : ""
                                }
                                onClick={() =>
                                  setSelectIcon(() => ({
                                    ...defaultState,
                                    image: true,
                                  }))
                                }
                              >
                                <i class="fa-solid fa-image"></i>
                              </li>
                            </div>
                          </Tooltip>
                        </Flex>
                      </Flex>

                      <Flex
                        vertical
                        justify="center"
                        align="center"
                        className="demo"
                      >
                        <Flex
                          justify="center"
                          align="center"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Tooltip placement="bottom" title={videotext}>
                            <div>
                              <li
                                className={
                                  selecticon?.video
                                    ? "active_css_aichatbot"
                                    : ""
                                }
                                onClick={() =>
                                  setSelectIcon(() => ({
                                    ...defaultState,
                                    video: true,
                                  }))
                                }
                              >
                                <i class="fa-solid fa-video"></i>
                              </li>
                            </div>
                          </Tooltip>
                        </Flex>
                      </Flex>
                    </ul>
                    <div className="mx-3">
                      <Flex
                        vertical
                        justify="center"
                        align="center"
                        className="demo"
                      >
                        <Flex
                          justify="center"
                          align="center"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <Tooltip placement="bottom" title={deleteAllchat}>
                            <div>
                              <button
                                class="btn btn-link"
                                onClick={(e) => HandleClearChat(e)}
                                disabled={convertation == ""}
                              >
                                <i
                                  class={`fa-solid fa-trash-can   ${
                                    convertation?.length > 0
                                      ? "chatai_icon_css"
                                      : ""
                                  }`}
                                ></i>
                              </button>
                            </div>
                          </Tooltip>
                        </Flex>
                      </Flex>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="message_cssAiagent" ref={answerScroll}>
          {convertation.map((item, index) => {
            return (
              <div
                className="wid-flx"
                key={index}
                style={{ width: "100%", maxWidth: "98%" }}
              >
                <div className="right-ms">
                  <div className="Question_cssAiagent">
                    <p className="mx-3 my-2" style={{ color: "black" }}>
                      <Question
                        hasAudio={item?.hasAudio}
                        userAudio={item?.userAudio}
                        question={item?.question}
                      />
                    </p>
                  </div>
                </div>
                <div className="left-ms">
                  <div className="Answer_cssAiagent my-4">
                    <img
                      className="AI-image"
                      src="https://img.freepik.com/premium-vector/brain-logo-sticker-illustration-creative-thinking-concept_1058532-10550.jpg"
                      alt=""
                    />
                    <Answer
                      answer={item?.answer}
                      audio={item?.audio}
                      hasAudio={item?.hasAudio}
                      animateText={item?.animate}
                      hasImage={item?.hasImage}
                      hasVideo={item?.hasVideo}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ChatAI;
