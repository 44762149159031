// import React, { useState } from "react";
import React, { useCallback, useState } from "react";

import { LoginSocialFacebook } from "reactjs-social-login";

import { FacebookLoginButton } from "react-social-login-buttons";

// import FacebookLogin from "react-facebook-login";

const Loginwithfb = () => {
  //   const [data, setData] = useState("");

  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);
  console.log(profile, "proooo");

  const onLoginStart = useCallback(() => {
    alert("login start");
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider("");
    alert("logout success");
  }, []);

  //   console.log(data, "dataa");
  //   const responseFacebook = (response) => {
  //     console.log(response);
  //     setData(response);
  //   };

  return (
    <div className="App">
      <h1>LOGIN WITH FACEBOOK </h1>

      {/* {data ? <button>LOgout</button>:<> */}

      {/* <FacebookLogin
        appId="962806012204358" // APP ID NOT CREATED YET
        fields="name,email,picture"
        autoLoad={true}
       
        scope="public_profile,user_friends,user_actions.books"
        callback={responseFacebook}
      />
      <br />
      <br />

      {data.name}
      {data.email} */}

      {/* <form action="">
        <label htmlFor="first">Username:</label>
        <input
          type="text"
          id="first"
          name="first"
          placeholder="Enter your Username"
          required
        />

        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          placeholder="Enter your Password"
          required
        />

        <div className="wrap">
          <button type="submit" onclick="solve()">
            Submit
          </button>
        </div>
      </form> */}

      {profile && provider ? (
        <>
          <h4> {profile?.email}</h4>
          <h4> {profile?.first_name}</h4>
          <h4> {profile?.last_name}</h4>
          {/* <img src={profile?.picture?.data?.url} /> */}
          <button onClick={onLogoutSuccess}>Logout</button>
        </>
      ) : (
        <>
          {/* <h1 className="title">ReactJS Social Login</h1> */}
          <LoginSocialFacebook
            // isOnlyGetToken
            fields="first_name,last_name,picture,email"
            appId={process.env.REACT_APP_FB_APP_ID || "962806012204358"}
            onLoginStart={onLoginStart}
            onResolve={({ provider, data }) => {
              setProvider(provider);
              setProfile(data);
            }}
            onReject={(err) => {
              console.log(err);
            }}
          >
            <FacebookLoginButton />
          </LoginSocialFacebook>
        </>
      )}
    </div>
  );
};

export default Loginwithfb;
