import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-toastify";
import {
  GetDecryptAccessTokenformat,
  GetDecryptEmailformat,
  GetDecryptReferenceNumberformat,
  SetEncryptAccessTokenformat,
  SetEncryptEmailformat,
  SetEncryptPasswordformat,
  SetEncryptReferenceNumberformat,
  SetEncryptUserNameformat,
  SetUserData,
} from "../../Components/Common/Common";

const initialState = {
  email: "",
  googleSigninData: [],
  profileDetails: [],
  isLoading: false,
  isInsiteDataLoading: true,
  wordsData: [],
  InstagramUserDetails: "",
  InstagramProfileData: "",
  ImageCaptionGenerate: "",
  TokenID: "",
  HashedTokenID: "",
  S3BucketImage: "",
  getUserWalletDetails: "",
  walletBalanceUser: "",
  userPrivateKey: "",
  transactionHistory: [],
  AiAgentResponse: [],
  BillingCostOpenAI: {},
  BillingCostOpenAIAmount: [],
  hasInstaAccess: false,
  ServerDailyCost: {},
  StabilityDiffusion: {},
  userlimitdata: "",
  CostGraph: {},
};

export const globalSlice = createSlice({
  name: "globalSlice",
  initialState,
  reducers: {
    HandleEmaildata: (state, actions) => {
      state.email = actions?.payload;
    },
    HandleGoogleSigninData: (state, actions) => {
      state.googleSigninData = actions?.payload;
    },
    HandleProfileDetails: (state, actions) => {
      state.profileDetails = actions?.payload;
    },
    SetLoading: (state, actions) => {
      state.isLoading = actions?.payload;
    },
    SetDataurl: (state, actions) => {
      state.Dataredirect = actions?.payload;
    },
    SetBillingCostOpenAI: (state, actions) => {
      state.BillingCostOpenAI = actions?.payload;
    },

    HandleSetBillingCostOpenAI: (state, actions) => {
      state.BillingCostOpenAIAmount = actions?.payload;
    },

    HandleMaildata: (state, actions) => {
      state.wordsData = actions?.payload;
    },
    HandleAiagentdata: (state, actions) => {
      state.AiAgentResponse = actions?.payload;
    },
    HandleWalletDetails: (state, actions) => {
      state.getUserWalletDetails = actions?.payload;
    },
    HandleUserPrivatekeyDetails: (state, actions) => {
      state.userPrivateKey = actions?.payload;
    },
    HandleWalletBalance: (state, actions) => {
      state.walletBalanceUser = actions?.payload;
    },

    HandleInstagramUserData: (state, actions) => {
      state.InstagramUserDetails = actions?.payload;
    },

    HandleUpdateHasInstaAccess: (state, actions) => {
      state.hasInstaAccess = actions?.payload;
    },

    HandleTransactionHistory: (state, actions) => {
      state.transactionHistory = actions?.payload;
    },

    HandleInstagramUserProfileData: (state, actions) => {
      state.InstagramProfileData = actions?.payload;
    },
    HandleImageCaptionGenerate: (state, actions) => {
      state.ImageCaptionGenerate = actions?.payload;
    },

    HandleTokenIdGenerate: (state, actions) => {
      state.TokenID = actions?.payload;
    },
    HandleHashedTokenId: (state, actions) => {
      state.HashedTokenID = actions?.payload;
    },
    HandleS3BucketImage: (state, actions) => {
      state.S3BucketImage = actions?.payload;
    },
    SetInsiteLoading: (state, actions) => {
      state.isInsiteDataLoading = actions?.payload;
    },
    HandleServerDailyCost: (state, action) => {
      state.ServerDailyCost = action?.payload;
    },
    HandleStabilityDiffusionCost: (state, action) => {
      state.StabilityDiffusion = action?.payload;
    },
    HandleUserlimit: (state, action) => {
      state.userlimitdata = action?.payload;
    },
    HandlegetServerCostGraph: (state, action) => {
      state.CostGraph = action?.payload;
    },
  },
});

export const {
  HandleEmaildata,
  HandleS3BucketImage,
  HandleGoogleSigninData,
  HandleProfileDetails,
  SetLoading,
  HandleMaildata,
  HandleInstagramUserData,
  HandleHashedTokenId,
  HandleTokenIdGenerate,
  HandleWalletBalance,
  HandleWalletDetails,
  HandleTransactionHistory,
  HandleUserPrivatekeyDetails,
  SetBillingCostOpenAI,
  HandleSetBillingCostOpenAI,
  HandleStabilityDiffusionCost,
  HandleUserlimit,
  HandlegetServerCostGraph,
} = globalSlice.actions;

export default globalSlice.reducer;

export function ReGenerateToken(ApiFunction, body, dispatch) {
  return async () => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/refreshAccessToken`;
      const data = {
        email: GetDecryptEmailformat(),
        reference_number: GetDecryptReferenceNumberformat(),
      };
      const response = await axios.post(url, data);

      if (response?.data) {
        SetLoading(false);

        SetEncryptAccessTokenformat(response?.data?.access_token);
        if (ApiFunction) {
          dispatch(ApiFunction(body));
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      SetLoading(false);
    }
  };
}

export function LoginHandler({ data, navigate, dispatch }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/googleSignIn`;
      const response = await axios.post(url, data);
      if (response?.data) {
        dispatch(SetLoading(false));
        SetEncryptEmailformat(response?.data?.data[0].email);
        SetEncryptReferenceNumberformat(
          response?.data?.data[0]?.reference_number
        );
        SetEncryptAccessTokenformat(response?.data?.access_token);
        SetUserData(response?.data?.data[0]?.username);

        // sessionStorage?.setItem("email", response?.data?.data[0].email);
        // sessionStorage?.setItem("reference_number", response?.data?.data[0]?.reference_number);
        // sessionStorage?.setItem("access_token", response?.data?.access_token);
        dispatch(
          globalSlice?.actions?.HandleEmaildata(response?.data?.data[0].email)
        );
        dispatch(
          globalSlice?.actions.HandleGoogleSigninData(response?.data?.data)
        );
        if (response?.data?.data[0]?.email_verified === 0) {
          navigate("/verifyotp");
        } else if (response?.data?.data[0]?.phone_verified === 0) {
          navigate("/otp");
        } else {
          navigate("/dashboard");
        }
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function RegisterUser({ registerData, dispatch, callback, navigate }) {
  return async () => {
    try {
      dispatch(SetLoading(true));
      // let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/signUp`;

      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/signUp`;
      const response = await axios.post(url, registerData);
      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        if (response?.data?.error) {
          toast.info(response?.data?.message);
        }

        if (callback) callback(response?.data);

        navigate("/");
        return response?.data;
      } else {
        dispatch(SetLoading(false));
        toast.error(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.errors[0]?.msg);
      dispatch(SetLoading(false));
    }
  };
}

export function LoginUser({ loginData, navigate, dispatch, Callback }) {
  return async () => {
    try {
      let email = GetDecryptEmailformat();
      console.log(email, "emaillll");
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/signIn`;
      const response = await axios.post(url, loginData);
      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        dispatch(
          globalSlice?.actions.HandleGoogleSigninData(response?.data?.data)
        );
        SetEncryptEmailformat(response?.data?.data[0].email);
        SetEncryptReferenceNumberformat(
          response?.data?.data[0]?.reference_number
        );
        SetEncryptAccessTokenformat(response?.data?.access_token);
        SetUserData(response?.data?.data[0]?.username);
        if (Callback) {
          setTimeout(() => {
            if (email) {
              Callback();
            }
            console.log(email, "emmmmmmmmmm");
          }, 1000);
        }
        // sessionStorage?.setItem("email", response?.data?.data[0]?.email);
        // sessionStorage?.setItem("reference_number", response?.data?.data[0]?.reference_number);
        // sessionStorage?.setItem("access_token", response?.data?.access_token);
        if (response?.data?.data[0]?.email_verified === 0) {
          navigate("/verifyotp");
        } else if (response?.data?.data[0]?.phone_verified === 0) {
          navigate("/otp");
        } else {
          navigate("/dashboard");
        }
        return response?.data;
      }
    } catch (error) {
      // toast.error(error?.response?.data?.errors[0]?.msg);
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function LogoutHandler({ obj, navigate, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/signOut`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));

        localStorage?.removeItem("email");
        localStorage?.removeItem("access_token");
        localStorage?.removeItem("reference_number");
        localStorage?.removeItem("role");
        localStorage?.removeItem("user_id");
        localStorage?.removeItem("UserName");
        sessionStorage?.removeItem("username");
        sessionStorage?.removeItem("password");
        // deleteCookie("email");
        // deleteCookie("access_token");
        // deleteCookie("reference_number");
        // deleteCookie("role")
        dispatch(globalSlice?.actions?.HandleWalletDetails(""));
        dispatch(globalSlice?.actions?.HandleInstagramUserData(""));
        dispatch(globalSlice?.actions?.HandleTokenIdGenerate(""));
        dispatch(globalSlice?.actions?.HandleHashedTokenId(""));
        dispatch(globalSlice?.actions?.HandleInstagramUserProfileData(""));
        dispatch(globalSlice?.actions?.HandleUpdateHasInstaAccess(false));
        toast.success(response?.data?.message);
        navigate("/");
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, navigate, dispatch };
        dispatch(ReGenerateToken(LogoutHandler, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function AddPhoneNumber({ obj, dispatch, callback }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/addPhoneNumber`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.data?.message);
        if (callback) callback(response?.data);
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch, callback };
        dispatch(ReGenerateToken(AddPhoneNumber, body, dispatch));
        return;
      }

      toast.error(
        error?.response?.data?.message === "Invalid phone." &&
          "Invalid phone number"
      );
    }
  };
}

export function ConfirmEmail({ obj, navigate, dispatch }) {
  // const token = sessionStorage?.getItem("access_token");
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/confirmEmail`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        toast.success(response?.data?.message);
        if (localStorage.getItem("isManual")) {
          navigate("/");
          localStorage.removeItem("isManual");
        } else {
          navigate("/otp");
        }
        localStorage.removeItem("isOtpSent");
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, navigate, dispatch };
        dispatch(ReGenerateToken(ConfirmEmail, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function VerifiedPhone({ obj, navigate, dispatch }) {
  // const token = sessionStorage?.getItem("access_token");
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    let { email, reference_number } = obj;
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/verifyPhoneNumber`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        // dispatch(RequestEmailOtp({ email, reference_number }));
        navigate("/dashboard");
        return response?.data;
      }
    } catch (error) {
      if (dispatch) {
        dispatch(SetLoading(false));
        // toast.error(error.response.data.message);
      }
    }
  };
}

export function UpdateProfile({ obj, navigate, dispatch, callback }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/updateUserProfile`;
      const response = await axios.patch(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetProfile({ dispatch, obj }));
        toast.success(response?.data?.message);
        if (callback) {
          dispatch(callback(response.data));
        }
        // navigate("/profile");
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, navigate, dispatch, callback };
        dispatch(ReGenerateToken(UpdateProfile, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function GetProfile({ obj, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  console.log(obj, "obj");

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/getUserProfile?reference_number=${obj?.reference_number}&email=${obj?.email}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(
          globalSlice?.actions?.HandleProfileDetails(response?.data?.data)
        );

        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(GetProfile, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function RequestEmailOtp({ obj, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/requestEmailOtp`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        localStorage.setItem("isOtpSent", true);
        toast.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      if (error?.response?.data?.message && error?.response?.data?.success) {
        toast.success(error?.response?.data?.message);
        localStorage.setItem("isOtpSent", true);
      } else {
        toast.error(error?.message);
      }
      if (dispatch) {
        dispatch(SetLoading(false));
      }
    }
  };
}

export function InstagramHandler({ obj, dispatch }) {
  // const token = GetDecryptAccessTokenformat();
  // const reqoptions = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/auth/instagram`;
      const response = await axios.post(url, obj);

      if (response?.data) {
        dispatch(SetLoading(false));
        if (response?.data?.success === true) {
          toast.success(response?.data?.message);
          // navigate("/modal")
          // navigate( response?.data?.data)
          // window.location.href = response?.data?.data;
          // window.open(response?.data?.data, "_blank");
          window.open(
            response?.data?.data,
            "AreaX",
            "height=500,width=500,left=450,top=100"
          );
        } else {
          toast.error(response?.data?.message);
        }

        return response?.data;
      }
    } catch (error) {
      if (error?.response?.data?.message && error?.response?.data?.success) {
        toast.success(error?.response?.data?.message);
      } else {
        toast.error(error?.response?.data?.message);
      }
      if (dispatch) {
        dispatch(SetLoading(false));
      }
    }
  };
}

export function InstagramRevoke({ obj, dispatch }) {
  // const reqoptions = {
  //   headers: {
  //     Authorization: `Bearer ${token}`,
  //   },
  // };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/auth/instagram`;
      const response = await axios.post(url, obj);
      if (response?.data) {
        dispatch(SetLoading(false));
        if (response?.data?.success === true) {
          toast.success(response?.data?.message);
          // navigate( response?.data?.data)
          // window.location.href = response?.data?.data;

          window.open(
            response?.data?.data,
            "AreaX",
            "height=500,width=500,left=450,top=100"
          );
          // win.focus()
        } else {
          toast.error(response?.data?.message);
        }
        return response?.data;
      }
    } catch (error) {
      if (error?.response?.data?.message && error?.response?.data?.success) {
        toast.success(error?.response?.data?.message);
      } else {
        toast.error(error?.response?.data?.message);
      }
      if (dispatch) {
        dispatch(SetLoading(false));
      }
    }
  };
}

// export function GetGmailAuth({ obj, dispatch, email, navigate }) {
//   const reqoptions = {
//     headers: {
//       "Content-Type": "application/json",
//     },
//   };

//   return async () => {
//     try {
//       dispatch(SetLoading(true));
//       let url = `${process.env.REACT_APP_apiBaseUrl}/google-api/authorize`;
//       const response = await axios.post(url, obj, reqoptions);

//       console.log(response?.data?.message.split("&")[2].slice(13), "ress");

//       if (response?.data?.success === true) {
//         dispatch(SetLoading(false));
//         if (response?.data?.message) {
//           dispatch(GetMailRidirect({ email, dispatch, navigate }));

//           // Check if 'firstTimeNavigation' flag is set in localStorage
//           let isFirstTimeNavigation = localStorage.getItem('firstTimeNavigation');

//           if (isFirstTimeNavigation === null) {
//             // Set flag to indicate first time navigation
//             localStorage.setItem('firstTimeNavigation', 'true');
//             isFirstTimeNavigation = 'true';
//           }

//           // Construct the URL with query parameter indicating the navigation status
//           const redirectUrl = new URL(response?.data?.message);
//           redirectUrl.searchParams.set('firstTime', isFirstTimeNavigation);

//           // Navigate to the URL with the query parameter
//           window.location.href = redirectUrl.toString();

//           // Update localStorage to indicate that the first navigation has been handled
//           localStorage.setItem('firstTimeNavigation', 'false');
//         }

//         return response?.data;
//       }
//     } catch (error) {
//       toast.error(error.response.data.message);
//       dispatch(SetLoading(false));
//     }
//   };
// }

export function GetGmailAuth({ obj, dispatch }) {
  const reqoptions = {
    headers: {
      // Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/google-api/authorize`;
      const response = await axios.post(url, obj, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        // toast.success(response?.data?.message);

        if (response?.data?.message) {
          // dispatch(GetMailRidirect({email, dispatch,navigate}))
          window.location.href = response?.data?.message;

          // const ref = window.open(response?.data?.message, "AreaX", "height=500,width=500,left=450,top=100");
        }

        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(GetGmailAuth, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function SearchByMailWords({ obj, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  console.log(token, "token");
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/google-api/fetch_emails`;
      const response = await axios.post(url, obj, reqoptions);

      if (response?.data?.success === true) {
        // toast.success(response?.data?.message);
        dispatch(SetLoading(false));
        if (response?.data?.message) {
          dispatch(
            globalSlice?.actions?.HandleMaildata(response?.data?.message)
          );
        }
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(SearchByMailWords, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function InstagramMessageApi({
  instaonchangedata,
  dispatch,
  fileupload,
  email,
  referenceNumber,
  obj,
}) {
  return async () => {
    const reqoptions = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/instagram/api/v1/messaging/caption-image`;

      let form_data = new FormData();
      form_data.append("username", instaonchangedata?.username);
      form_data.append("password", instaonchangedata?.password);
      form_data.append("caption", instaonchangedata?.caption);
      form_data.append("image", fileupload);
      form_data.append("email", email);

      const response = await axios.post(url, form_data, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        SetEncryptUserNameformat(instaonchangedata?.username);
        SetEncryptPasswordformat(instaonchangedata?.password);
        toast.success(response?.data?.message);
        dispatch(GetInstagramDetails({ email, referenceNumber, dispatch }));
        dispatch(GetInstagramUserDetailsInfo({ obj, dispatch }));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = {
          instaonchangedata,
          dispatch,
          fileupload,
          email,
          referenceNumber,
          obj,
        };
        dispatch(ReGenerateToken(InstagramMessageApi, body, dispatch));
        return;
      }
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };
}

export function GetInstagramDetails({ email, referenceNumber, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async () => {
    try {
      dispatch(globalSlice?.actions?.SetInsiteLoading(true));
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/getInstagramBasicInfo?email=${email}&reference_number=${referenceNumber}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        console.log(response, "resssssssspppp");

        dispatch(SetLoading(false));
        dispatch(
          globalSlice?.actions?.HandleInstagramUserData(response?.data?.data)
        );
        dispatch(
          globalSlice?.actions?.HandleUpdateHasInstaAccess(
            response?.data?.success
          )
        );
        dispatch(globalSlice?.actions?.SetInsiteLoading(false));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      dispatch(globalSlice?.actions?.SetInsiteLoading(false));
      if (error?.response?.status === 401) {
        let body = { email, referenceNumber, dispatch };
        dispatch(ReGenerateToken(GetInstagramDetails, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

// **********GENERATED CAPTION API**************************

export function GeneratedCaptionApi({
  email,
  referenceNumber,
  image,
  dispatch,
  obj,
}) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return async () => {
    try {
      dispatch(SetLoading(true));
      // let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/upload/imageFile`;
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/chat-gemini/v1/captionGenerator`;
      let form_data = new FormData();
      form_data.append("image", image);
      form_data.append("reference_number", referenceNumber);
      form_data.append("email", email);
      const response = await axios.post(url, form_data, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        dispatch(UploadToBucket({ obj }));
        dispatch(
          globalSlice?.actions?.HandleImageCaptionGenerate(response?.data?.data)
        );

        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        const body = { email, referenceNumber, image, dispatch, obj };
        dispatch(ReGenerateToken(GeneratedCaptionApi, body, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    }
  };
}

export function UploadToBucket({ obj }) {
  // const token = sessionStorage?.getItem("access_token");
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      // let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/upload/imageFile`;
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/uploadToBucket`;
      let form_data = new FormData();
      form_data.append("file", obj?.file);
      form_data.append("file_name", obj?.file_name);
      form_data.append("file_type", obj?.file_type);
      const response = await axios.post(url, form_data, reqoptions);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        dispatch(
          globalSlice?.actions?.HandleS3BucketImage(response?.data?.file_url)
        );
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        const body = { obj, dispatch };
        dispatch(ReGenerateToken(UploadToBucket, body, dispatch));
        return;
      }
      toast.error(error?.response?.data?.message);
    }
  };
}

// export function GetInstagramUserDetailsInfo({ obj, dispatch, callBack }) {
//   // const token = sessionStorage?.getItem("access_token");
//   const token = GetDecryptAccessTokenformat();
//   const reqoptions = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   return async () => {
//     try {
//       dispatch(SetLoading(true));
//       dispatch(globalSlice?.actions?.SetInsiteLoading(true));
//       let url = `${process.env.REACT_APP_apiBaseUrl}/instagram/messaging/private-user-profile-insight`;
//       const response = await axios.post(url, obj, reqoptions);
//       if (response?.data?.success) {
//         console.log(response, "resssssssspppp");

//         dispatch(SetLoading(false));
//         SetEncryptUserNameformat(obj?.username);
//         SetEncryptPasswordformat(obj?.password);
//         dispatch(globalSlice?.actions?.HandleInstagramUserProfileData(response?.data?.data));
//         dispatch(globalSlice?.actions?.SetInsiteLoading(false));
//         if (callBack) {
//           callBack();
//         }
//         return response?.data;
//       }
//     } catch (error) {
//       dispatch(globalSlice?.actions?.SetInsiteLoading(false));
//       dispatch(SetLoading(false));
//       if (error?.response?.status === 401) {
//         let body = { obj, dispatch };
//         dispatch(ReGenerateToken(GetInstagramUserDetailsInfo, body, dispatch));
//         return;
//       }

//       toast.error(error?.response?.data?.message);
//     }
//   };
// }

export function InstagramLoginDetails({
  obj,
  dispatch,
  TokenID,
  getUserWalletDetails,
  walletBalanceUser,
}) {
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/instagram/api/v1/messaging/private-user-login`;

      const response = await axios.post(url, obj);

      if (response?.data?.success) {
        toast.success(response?.data?.message);
        SetEncryptUserNameformat(obj?.username);
        SetEncryptPasswordformat(obj?.password);
        dispatch(
          GetInstagramUserDetailsInfo({
            obj,
            dispatch,
            TokenID,
            getUserWalletDetails,
            walletBalanceUser,
          })
        );

        dispatch(SetLoading(false));
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = {
          obj,
          dispatch,
          TokenID,
          getUserWalletDetails,
          walletBalanceUser,
        };
        dispatch(ReGenerateToken(InstagramLoginDetails, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function GetInstagramUserDetailsInfo({
  obj,
  dispatch,
  TokenID,
  getUserWalletDetails,
  walletBalanceUser,
}) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      dispatch(globalSlice?.actions?.SetInsiteLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/instagram/api/v1/messaging/private-user-profile-insight`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data?.success) {
        toast.success(response?.data?.message);
        dispatch(SetLoading(false));
        // SetEncryptUserNameformat(obj?.username);
        // SetEncryptPasswordformat(obj?.password);
        dispatch(
          globalSlice?.actions?.HandleInstagramUserProfileData(
            response?.data?.data
          )
        );
        dispatch(globalSlice?.actions?.SetInsiteLoading(false));

        if (
          walletBalanceUser &&
          walletBalanceUser !==
            "You have not enough balance 0.,please add more balance."
        ) {
          let comment_count = localStorage.getItem("CommentCount") || "200";
          let Multiple_Count = localStorage.getItem("MultipleCount") || "20";
          let isAutoMinting = localStorage.getItem("isAutoMinting");
          console.log(comment_count, "comment_count");
          console.log(Multiple_Count, "Multiple_Count");
          console.log(isAutoMinting, "isAutoMinting");

          let commentMoreThen200 = response?.data?.data?.insights?.filter(
            (item) =>
              item?.comment_count >= Number(comment_count) && !item?.is_minted
          );
          commentMoreThen200 = commentMoreThen200.map((item) => ({
            walletAddress: getUserWalletDetails,
            id: item.media_item_id,
            postUrl: item.share_link,
            platformType: item.share_link.split(".")[1],
            multiple: false,
            txnMethod: "Auto Add Post",
          }));

          const formData = {
            fbUsername: "0",
            instaUsername: obj?.username,
            linkedinUsername: "0",
            xUsername: "0",
            fbFollowers: "0",
            instaFollowers: `${response?.data?.data?.followersCount}` || "0",
            linkedinFollowers: "0",
            xFollowers: "0",
            walletAddress: getUserWalletDetails,
            txnMethod: "Auto Add Mint",
          };

          let postMultipledetails = null;
          const hasLength = response?.data?.data?.insights?.length;
          const insightsData = hasLength
            ? response?.data?.data?.insights.slice().reverse()
            : [];
          const lastPost = insightsData?.find(
            (obj) => obj?.is_already_mint_for_multiple !== 0
          );

          let lastCount = 0;
          if (lastPost) {
            lastCount = parseInt(lastPost?.is_already_mint_for_multiple);
          }

          if (
            response?.data?.data?.insights?.length >=
            (lastCount + Number(Multiple_Count) || 20)
          ) {
            const subArray = [
              ...(response?.data?.data?.insights
                ? [...response?.data?.data?.insights]
                : []),
            ].slice(lastCount);
            postMultipledetails = {
              postUrl: subArray.map((item) => item?.share_link),
              platformType: subArray.map(
                (item) => item?.share_link.split(".")[1]
              ),
              multiple: true,
              id: response?.data?.data?.insights[hasLength - 1]?.media_item_id,
              is_already_mint_for_multiple: hasLength,
              walletAddress: getUserWalletDetails,
              txnMethod: "Auto Add Multiple Post",
            };
          }

          if (TokenID && isAutoMinting) {
            if (commentMoreThen200 && commentMoreThen200?.length) {
              const postdetails = commentMoreThen200?.map((item) => ({
                ...item,
                userTokenID: TokenID,
              }));
              dispatch(AddPost({ postdetails, dispatch }));
            }

            if (postMultipledetails) {
              dispatch(
                AddMultiplePost({
                  postMultipledetails: {
                    ...postMultipledetails,
                    userTokenID: TokenID,
                  },
                  dispatch,
                })
              );
            }
            return response?.data;
          }

          if (TokenID) {
            return;
          }

          dispatch(
            AddMinting({
              formData,
              isAutoMinting,
              commentMoreThen200,
              postMultipledetails,
              dispatch,
            })
          );
        } else {
          // toast.error("Please add amount in your wallet!");
          return response?.data;
        }

        return response?.data;
      }
    } catch (error) {
      dispatch(globalSlice?.actions?.SetInsiteLoading(false));
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(GetInstagramUserDetailsInfo, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function GetInstagramHashTag({ objhashtag, dispatch }) {
  // const token = sessionStorage?.getItem("access_token");
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/instagram/messaging/private-hashtag`;
      const response = await axios.post(url, objhashtag, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { objhashtag, dispatch };
        dispatch(ReGenerateToken(GetInstagramHashTag, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

// export function InstagramMessageApi({ instaonchangedata, dispatch ,fileupload}) {
//   console.log(instaonchangedata,fileupload);

//   const reqoptions = {
//     headers: {
//       // Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//   };
//   return async () => {
//     try {
//       let url = `${process.env.REACT_APP_apiBaseUrl}/instagram/messaging/caption-image`;

//       let form_data = new FormData();
//       form_data.append("username", instaonchangedata?.username);
//       form_data.append("password", instaonchangedata?.password);
//       form_data.append("caption", instaonchangedata?.caption);
//       form_data.append("image", fileupload);

//       const response = await axios.post(url, form_data, reqoptions);
//       if (response?.data?.success === true) {
//         // toast.success(response?.data?.message);

//         return response?.data;
//       }
//     } catch (error) {
//       toast.error(error.response.data.message);
//       dispatch(SetLoading(false));
//     }
//   };
// }

// export function GetMailRidirect({ email, dispatch,navigate }) {
//   // const token = sessionStorage?.getItem("access_token");
//   const reqoptions = {
//     headers: {
//       // Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//   };
//   return async () => {
//     try {
//       dispatch(SetLoading(true));
//       let url = `${process.env.REACT_APP_apiBaseUrl}/google-api/authenticate_status?email=${email}`;
//       const response = await axios.get(url, email, reqoptions);
//       // console.log(response?.data?.message.split("&")[2].slice(13), "ress");
//       if (response?.data?.message==="Completed") {
//         dispatch(SetLoading(false));
// navigate("/mail")
//         console.log(response.data.message, "rppppppp");

//         return response?.data;
//       }
//     } catch (error) {
//       console.log(error,"errrrrrrrrrrrrrrrrrrrr");

//       toast.error(error?.response?.data?.message);
//       dispatch(SetLoading(false));
//     }
//   };
// }

// *********************BLOCKCHAIN API***********************************

export function PostTokenId(
  { obj, commentMoreThen200, isAutoMinting, postMultipledetails, dispatch },
  callBack
) {
  console.log(obj.token_id, "token_idtoken_idtoken_id");
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      dispatch(globalSlice?.actions?.SetInsiteLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/addBlockchainTokenId`;
      const response = await axios.post(url, obj, reqoptions);
      if (response?.data?.success) {
        dispatch(GetTokenId({ obj }, callBack));
        dispatch(SetLoading(false));
        const FunCallBack = (token_id) => {
          if (
            commentMoreThen200 &&
            commentMoreThen200.length &&
            isAutoMinting
          ) {
            const postdetails = commentMoreThen200.map((item) => ({
              ...item,
              userTokenID: token_id,
            }));
            dispatch(AddPost({ postdetails, dispatch }));
          }
          if (postMultipledetails && isAutoMinting) {
            dispatch(
              AddMultiplePost({
                postMultipledetails: {
                  ...postMultipledetails,
                  userTokenID: token_id,
                },
                dispatch,
              })
            );
          }
          callBack(token_id);
        };
        dispatch(PostTokenId({ obj }, FunCallBack));
        return response?.data;
      }
    } catch (error) {
      dispatch(globalSlice?.actions?.SetInsiteLoading(false));
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(PostTokenId, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

// export function PostTokenId({ obj, dispatch }) {

//   const token = GetDecryptAccessTokenformat();
//   const reqoptions = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   };
//   return async (dispatch) => {
//     try {
//       dispatch(SetLoading(true));
//       dispatch(globalSlice?.actions?.SetInsiteLoading(true));
//       let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/addTokenId`;
//       const response = await axios.post(url, obj,reqoptions);
//       if (response?.data?.success) {
//         dispatch(SetLoading(false));
//         dispatch(GetTokenId(obj))
//         return response?.data;
//       }
//     } catch (error) {
//       dispatch(globalSlice?.actions?.SetInsiteLoading(false));
//       dispatch(SetLoading(false));
//       if (error?.response?.status === 401) {
//         let body={obj, dispatch}
//         dispatch(ReGenerateToken(PostTokenId,body,dispatch));
//         return;
//       }

//       toast.error(error?.response?.data?.message);
//     }
//   };
// }

export function GetTokenId({ obj }, callBack) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/getBlockchainTokenId?reference_number=${obj?.reference_number}&email=${obj?.email}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        if (callBack) {
          callBack(response?.data?.data?.token_id);
        }
        console.log(response.data.data, "tokennnnnnn");
        dispatch(
          globalSlice?.actions?.HandleHashedTokenId(
            response.data.data.hashed_token_id
          )
        );
        dispatch(
          globalSlice?.actions?.HandleTokenIdGenerate(
            response?.data?.data.token_id
          )
        );
        return response?.data;
      }
    } catch (error) {
      SetLoading(false);
      if (callBack) {
        callBack(null);
      }
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(GetTokenId, body, dispatch));
        return;
      }

      // toast.error(error?.response?.data?.message);
    }
  };
}

export function GetUserWalletDetails({ obj, dispatch }) {
  console.log(obj, "objjjjjjjjjjjjjj");

  const user_id = obj?.user_id;

  console.log(user_id, "user_iduser_id");

  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/getUserWalletDetails?user_id=${obj?.user_id}`;
      const response = await axios.get(url, reqoptions);

      console.log(response, "resssssssspppp");

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        // let userBal={
        //   walletAddress:obj?.walletAddress
        // }
        // dispatch( GetUserWalletBalance({obj}))
        dispatch(GetTokenId({ obj, dispatch }));
        dispatch(
          globalSlice?.actions?.HandleWalletDetails(
            response?.data?.data?.walletAddress
          )
        );
        dispatch(
          globalSlice?.actions?.HandleUserPrivatekeyDetails(
            response?.data?.data?.privateKey
          )
        );

        return response?.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(GetUserWalletDetails, body, dispatch));
        return;
      }
      console.log(
        error?.response?.data?.message === "User wallet details not found",
        "errrrrrrrr"
      );
      if (error?.response?.data?.message === "User wallet details not found") {
        dispatch(UserWalletDetails({ user_id, dispatch, obj }));
      }

      // toast.error(error?.response?.data?.message);
    }
  };
}
export function GetUserWalletBalance({ getUserWalletDetails, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let objBalance = {
    walletAddress: getUserWalletDetails,
  };
  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/userWalletBalance`;
      const response = await axios.post(url, objBalance, reqoptions);

      console.log(response, "balanceee");

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));
        dispatch(
          globalSlice?.actions?.HandleWalletBalance(response?.data?.amount)
        );

        return response?.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { getUserWalletDetails, dispatch };
        dispatch(ReGenerateToken(GetUserWalletBalance, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function AddMinting({
  formData,
  commentMoreThen200,
  isAutoMinting,
  postMultipledetails,
  dispatch,
}) {
  const token = GetDecryptAccessTokenformat();
  const email = GetDecryptEmailformat();
  const reference_number = GetDecryptReferenceNumberformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/addMint`;
      const response = await axios.post(url, formData, reqoptions);

      console.log(response, "Complete response");
      console.log(response?.data, "Response data");
      console.log(response?.data?.data, "Response data object");

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        const tokenId = response?.data?.data?.tokenID;
        console.log(tokenId, "IDDDDDDDDDDDDD");
        if (!tokenId) {
          console.error("Token ID is undefined");
          return;
        }

        let obj = {
          email: email,
          reference_number: reference_number,
          token_id: `${tokenId}`,
        };

        dispatch(
          PostTokenId({
            obj,
            commentMoreThen200,
            isAutoMinting,
            postMultipledetails,
            dispatch,
          })
        );

        return response?.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { formData, dispatch };
        dispatch(ReGenerateToken(AddMinting, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function AddPost({ postdetails, dispatch, callBack }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/addPost`;
      for (const [index, item] of postdetails.entries()) {
        const response = await axios.post(url, item, reqoptions);

        if (response?.data?.success === true) {
          const mintUrl = `${process.env.REACT_APP_apiBaseUrl}/instagram/api/v1/messaging/mint-image`;
          const body = {
            media_item_id: item?.id,
            is_minted: "1",
          };
          await axios.post(mintUrl, body, reqoptions);
          if (callBack) {
            callBack(response?.data);
          }
        }

        if (index === postdetails.length - 1) {
          dispatch(SetLoading(false));
        }
      }
    } catch (error) {
      if (callBack) {
        callBack(null);
      }
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { postdetails, dispatch };
        dispatch(ReGenerateToken(AddPost, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function AddPostTest({ obj, dispatch }) {
  console.log(obj, "obbbbbbbbbbbbbbbbb");
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/addPost`;

      const response = await axios.post(url, obj, reqoptions);

      if (response?.data?.success === true) {
        SetLoading(false);
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(AddPostTest, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function AddMultiplePostTest({ postMultipledetails, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/multiplePost`;

      const response = await axios.post(url, postMultipledetails, reqoptions);

      if (response?.data?.success === true) {
        SetLoading(false);
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { postMultipledetails, dispatch };
        dispatch(ReGenerateToken(AddMultiplePostTest, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function AddMultiplePost({ postMultipledetails, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    console.log("AddMultiplePost");

    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/multiplePost`;
      const response = await axios.post(url, postMultipledetails, reqoptions);
      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        const mintMultipleurl = `${process.env.REACT_APP_apiBaseUrl}/instagram/api/v1/messaging/mint-multiple`;
        const body = {
          media_item_id:
            postMultipledetails?.id || postMultipledetails?.media_item_id,
          is_already_mint_for_multiple:
            postMultipledetails?.is_already_mint_for_multiple,
        };
        await axios.post(mintMultipleurl, body, reqoptions);

        return response?.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { postMultipledetails, dispatch };
        dispatch(ReGenerateToken(AddMultiplePost, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function GetTransactionHistory({ address }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/getUserWalletCompleteDetails?walletAddress=${address} `;
      const response = await axios.get(url, reqoptions);

      if (response?.data?.success === true) {
        dispatch(
          globalSlice?.actions?.HandleTransactionHistory(
            response?.data?.data?.walletDetails
          )
        );
        dispatch(SetLoading(false));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        let body = { dispatch };
        dispatch(ReGenerateToken(GetTransactionHistory, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function OpenAPIBillingCost() {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/ai/api/total_cost_Chat`;
      const response = await axios.get(url, reqoptions);
      console.log(response, "respppppppppp");
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(globalSlice?.actions?.SetBillingCostOpenAI(response?.data));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateToken(OpenAPIBillingCost, {}, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function OpenAICOstApi(change, dispatch) {
  console.log(dispatch, "dissss");
  return async (dispatch) => {
    try {
      let email = GetDecryptEmailformat();
      console.log(email, "emaillll");
      dispatch(SetLoading(true));
      let url = `https://9cc4-38-137-2-35.ngrok-free.app/ai/api/ai_agent_input`;
      const response = await axios.post(url, change);

      console.log(response?.data, "cssdcsswew");
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(
          globalSlice?.actions?.HandleSetBillingCostOpenAI(response?.data)
        );
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function UploadProfilePicture({ objforupdatepic, dispatch, obj }) {
  const token = GetDecryptAccessTokenformat();
  const formData = new FormData();
  formData.append("email", objforupdatepic?.email);
  formData.append("reference_number", objforupdatepic?.reference_number);
  formData.append("image", objforupdatepic?.image);

  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  return async () => {
    try {
      dispatch(SetLoading(true));
      const url = `${process.env.REACT_APP_apiBaseUrl}/api/v1/uploadUserProfilePicture`;
      const response = await axios.post(url, formData, reqoptions);
      console.log(response.data?.file_url, "respssssssss");
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetProfile({ dispatch, obj }));
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        const body = { objforupdatepic, dispatch };
        dispatch(ReGenerateToken(UploadProfilePicture, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function UserWalletDetails({ user_id, dispatch, obj }) {
  console.log(user_id, "user_iduser_iduser_iduser_iduser_iduser_id");
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/userWalletDetail`;
      let objdata = {
        user_id: user_id,
      };
      const response = await axios.post(url, objdata);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(GetUserWalletDetails({ obj, dispatch }));
        return response?.data;
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      dispatch(SetLoading(false));
    }
  };
}

export function GetServerDailyCost({ obj, dispatch }) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return async (dispatch) => {
    try {
      SetLoading(true);
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/getServerDailyCost`;
      const response = await axios.get(url, reqoptions);
      if (response?.data?.success === true) {
        const blendedCost = response?.data?.data?.BlendedCost;
        dispatch(SetLoading(false));
        // Dispatch the action with the blended cost details
        dispatch(globalSlice?.actions?.HandleServerDailyCost(blendedCost));
        return response?.data;
      } else {
        // Handle case where success is false
        console.error("API call was not successful:", response?.data?.message);
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(GetServerDailyCost, body, dispatch));
        return;
      }
      console.error(
        error?.response?.data?.message || "An error occurred",
        "errrrrrrrr"
      );
      // Optionally, use a toast or other UI feedback mechanism
      // toast.error(error?.response?.data?.message);
    }
  };
}

export function StabilityDiffusionCost() {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/ai/api/total_cost_sd_image`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(
          globalSlice?.actions?.HandleStabilityDiffusionCost(response?.data)
        );
        return response?.data;
      }
    } catch (error) {
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateToken(StabilityDiffusionCost, {}, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function SetUserLimit({ obj, dispatch }) {
  const body = {
    limit: obj?.limit,
    user_id: obj?.user_id,
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));

      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/userLimitSet`;

      const response = await axios.post(url, body);

      if (response?.data?.success === true) {
        toast.success(response?.data?.message);
        dispatch(GetUserLimit({ body, dispatch }));
        dispatch(SetLoading(false));

        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let body = { obj, dispatch };
        dispatch(ReGenerateToken(SetUserLimit, body, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function GetUserLimit({ body, dispatch }) {
  console.log(body, "bodyyyyyyyyyyyyyyyyyyyyyyy");
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/getUserLimitSet/${body?.user_id}`;

      const response = await axios.get(url);

      if (response?.data?.success === true) {
        dispatch(SetLoading(false));

        dispatch(
          globalSlice?.actions?.HandleUserlimit(response.data.data.limit)
        );
        return response.data;
      }
    } catch (error) {
      SetLoading(false);
      if (error?.response?.status === 401) {
        let bodydata = { body, dispatch };
        dispatch(ReGenerateToken(GetUserLimit, bodydata, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}

export function getServerCostGraph(year) {
  const token = GetDecryptAccessTokenformat();
  const reqoptions = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return async (dispatch) => {
    try {
      dispatch(SetLoading(true));
      let url = `${process.env.REACT_APP_apiBaseUrl}/blockchain/api/v1/getServerCostGraph/${year}`;
      const response = await axios.get(url, reqoptions);
      if (response?.data) {
        dispatch(SetLoading(false));
        dispatch(
          globalSlice?.actions?.HandlegetServerCostGraph(response?.data)
        );
        return response?.data;
      }
    } catch (error) { 
      dispatch(SetLoading(false));
      if (error?.response?.status === 401) {
        dispatch(ReGenerateToken(getServerCostGraph, { year }, dispatch));
        return;
      }

      toast.error(error?.response?.data?.message);
    }
  };
}
