import { Routes, Route } from "react-router-dom";
import Layout from "../../Components/Layout/index";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import Login from "../../Components/Authentication/Login";
import OTP from "../../Components/Authentication/OTP";
import VerifyOTP from "../../Components/Authentication/VerifyOTP";

function App() {
  return (
    <>
      <Layout>
        <Routes>
        <Route path="/" element={<Login />}></Route>
          <Route path="/admindashboard" element={<Dashboard/>}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/otp" element={<OTP />}></Route>
          <Route path="/verifyotp" element={<VerifyOTP />}></Route>
        </Routes>
      </Layout>
    </>
  );
}

export default App;
